/* eslint-disable no-param-reassign */
import { createAction, handleActions, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { Dispatch } from 'redux';
import dayjs from 'dayjs';
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import history from 'store/history';

import { api } from 'util/api';
import { 
	V1UserOnlineRegistrationClinicScheduleListRequestParams, 
	ClinicScheduleResource
} from 'util/api/swaggerApi/data-contracts';

import { advanceToNextStep } from 'util/routeHelper';
import { ROUTE_PATHS } from 'models/sideMenu';
import { updateAccessToken } from './auth';

import { GetState, State as GlobalState } from './reducers';
import {
	STORAGE_KEY,
	StorageType,
	setItemToStorage,
} from 'util/hook/useStorage';

export const REGISTRATION_RESULT_QUERY = {
	SUCCESS: 'success',
	FAILED: 'failed',
};

export interface DayType {
	id: number;
	label: string;
	date: string;
}

export interface WeekDaysType {
	id: number;
	startDate: string;
	endDate: string;
	days: DayType[];
}

export interface WeekDayIdType {
	weekId: number;
	dayId: number;
}

export interface RawHospitalClinicResource {
	id?: number;
	name?: string;
	code?: string;
	phone_1?: string;
	phone_2?: string;
	weight?: number;
}

export interface ExtendedClinicScheduleResource extends ClinicScheduleResource {
	toolTipContent: string;
	disabled: boolean;
	noticeLabel: string;
	modalContext: string;
	id: number;
	departmentContext: string;
	clinicPhone1: string;
	clinicPhone2: string;
	canContactByPhone: boolean;
}

interface GroupedData {
  [key: number]: ExtendedClinicScheduleResource[];
}

interface ListData {
	[key: number]: GroupedData;
}

export interface State {
	loading: boolean;
	weekDays: WeekDaysType[];
	selectedWeekAndDay: WeekDayIdType;
	outpatientList: ListData;
	selectedOutpatient: ExtendedClinicScheduleResource;
	selectedOutpatientDay: string;
	formUrl: string;
}

export const defaultState: State = {
	loading: false,
	weekDays: [],
	selectedWeekAndDay: {weekId: 0, dayId: 0},
	outpatientList: {},
	selectedOutpatient: {} as ExtendedClinicScheduleResource,
	selectedOutpatientDay: '',
	formUrl: '',
};


// 下拉選單可以選擇的週數
export const defaultWeeksRange = 4;

// 將傳入的日期區間(2024/03/14, 2024/03/20)變成 03/14 (一) 格式 
const convertDayFormat = (startDate: string, endDate: string) => {
	const format = 'YYYY/MM/DD';
	const dayOptions = [];
	let current = dayjs(startDate);
	const end = dayjs(endDate);
	let id = 0;
	dayjs.extend(isSameOrBefore);

	while (current.isSameOrBefore(end)) {
		const weekDayNames = ['日', '一', '二', '三', '四', '五', '六'];
		const date = current.format(format);
		const month = current.format('MM');
		const day = current.format('DD');
		const weekDay = weekDayNames[current.day()];

		dayOptions.push({
			id,
			label: `${month}/${day} (${weekDay})`,
			date,
		});
		id += 1;
		current = current.add(1, 'day'); // 移至下一天
	}

	return dayOptions;
}


const formatDate = (date: Date): string => {
	const year = date.getFullYear();
	const month = (`0${date.getMonth() + 1}`).slice(-2);
	const day = (`0${date.getDate()}`).slice(-2);
	return `${year}/${month}/${day}`;
};

export const initWeekDayData = createAction(
	'INIT_WEEK_DAY_DATA',
	() => {
		function generateWeeksOptions() {
			const weeksOptions = [];
			const currentDate = new Date();
			currentDate.setHours(0, 0, 0, 0);
			// currentDate.getDay(): 0-6, 0: Sunday, 6: Saturday
			// currentDate.getDate(): ex. 14 號


			for (let i = 0; i < defaultWeeksRange; i += 1) {
				const startDate = new Date();
				startDate.setDate(currentDate.getDate() + (i * 7));
				const endDate = new Date(startDate);
				endDate.setDate(startDate.getDate() + 6);

				const formattedStartDate = formatDate(startDate);
				const formattedEndDate = formatDate(endDate);
				
				weeksOptions.push({
					id: i,
					startDate: formattedStartDate,
					endDate: formattedEndDate,
					days: convertDayFormat(formattedStartDate, formattedEndDate),
					value: `${startDate.toISOString().split('T')[0]}至${endDate.toISOString().split('T')[0]}`,
				});
			}
			return weeksOptions;
		}
		
		return generateWeeksOptions();
	},
);

export const setSelectedWeekAndDay = createAction(
	'SET_SELECTED_WEEK_AND_DAY',
	(weekId: number, dayId: number) => ({ weekId, dayId }),
);

export const setSelectedOutpatient = createAction(
	'SET_SELECTED_OUTPATIENT',
	(outpatient: ExtendedClinicScheduleResource) => outpatient,
);

export const setSelectedOutpatientDay = createAction(
	'SET_SELECTED_OUTPATIENT_DAY', () => 
		(_: Dispatch, getState: GetState) => {
			const { outpatientRegistration: { selectedWeekAndDay, weekDays } } = getState();
			const { days } = weekDays[selectedWeekAndDay.weekId];
			const currentDay = days[selectedWeekAndDay.dayId].label.split(' ')[1];
			return currentDay;
		},
);


export const getOutpatientRegistrationList = createAction(
	'GET_OUTPATIENT_REGISTRATION_LIST', () => async (_: Dispatch, getState: GetState) => {
		const { v1UserOnlineRegistrationClinicScheduleList } = api;
		const { doctorDepartmentRegistration: { selectedDepartment, selectedBranch } } = getState();
		const { outpatientRegistration: { selectedWeekAndDay, weekDays, outpatientList } } = getState();

		// 如果 outpatientList 已經存在該週期的資料，就不再重新取得
		 if (outpatientList[selectedWeekAndDay.weekId]) {
			return outpatientList;
		 }
		const isCheckDepartmentKeywords = !!(selectedDepartment?.value?.mappingKeywords && selectedDepartment.value.mappingKeywords.length !== 0);

		const startDate = dayjs(weekDays[selectedWeekAndDay.weekId].startDate).format('YYYYMMDD');
		const endDate = dayjs(weekDays[selectedWeekAndDay.weekId].endDate).format('YYYYMMDD');
		// const allClinicsCode: string[] = selectedBranch?.value?.clinics?.map(clinic => clinic.code as string) || [];

		// const promises = allClinicsCode.map(code => {
		// 	const params: V1UserOnlineRegistrationClinicScheduleListRequestParams = {
		// 		start_date: startDate,
		// 		end_date: endDate,
		// 		hospital_clinic_code: code,
		// 	};
		// 	return v1UserOnlineRegistrationClinicScheduleList(params);
		// });
		const params:any = {
			start_date: startDate,
			end_date: endDate,
			department: selectedDepartment.value.name,
			doctor_name: selectedBranch.value.name
		}

		let allOutpatientList: ClinicScheduleResource[] = [];
		try {
			// const results = await Promise.allSettled(promises);
			// results.forEach(result => {
			// 	if (result.status === 'fulfilled') {
			// 		const { data } = result.value;
			// 		data?.data?.forEach((item:ClinicScheduleResource) => {
			// 			// item.department: department: "皮膚科(民權)"
			// 			if (isCheckDepartmentKeywords) {
			// 				const isMatch = selectedDepartment?.value?.mappingKeywords?.some(keyword => item?.department?.includes(keyword));
			// 				if (isMatch) {
			// 					allOutpatientList.push(item);
			// 				}
							
			// 			} else {
			// 				const isMatch = item?.department?.includes(selectedDepartment?.value?.name || '');
			// 				if (isMatch) {
			// 					allOutpatientList.push(item);
			// 				}
			// 			}
			// 		});
			// 	}
			// });
			const {data} = await v1UserOnlineRegistrationClinicScheduleList(params);
			data?.data?.forEach((item:ClinicScheduleResource) => {
				// item.department: department: "皮膚科(民權)"
				if (isCheckDepartmentKeywords) {
					const isMatch = selectedDepartment?.value?.mappingKeywords?.some(keyword => item?.department?.includes(keyword));
					if (isMatch) {
						allOutpatientList.push(item);
					}
					
				} else {
					const isMatch = item?.department?.includes(selectedDepartment?.value?.name || '');
					if (isMatch) {
						allOutpatientList.push(item);
					}
				}
			});
			console.log('allOutpatientList', allOutpatientList);
			
		} catch (error) {
			console.log('getOutpatientRegistrationList error', error);
		}

		// 判斷不為"無"和空值
		function isValidName(inputValue: string | undefined) {
			return inputValue && inputValue !== '無';
		}

		function notContainKeyword(string: string) {
			const excludedStrings = ["轉檢診", "3D4D超音波", "尿動力檢查", "溫柔生產"];
			return !excludedStrings.some(excluded => string.includes(excluded));
		}

		function includeKeywords(text: string) {
			return text.includes('單週') && text.includes('雙週');
		}

		const newOutpatientList = [] as ExtendedClinicScheduleResource[];
		allOutpatientList.forEach((item, index) => {
			let doctorName = item.doctorName && item.doctorName.replace(/\(.*?\)/, '').trim();
			let toolTipContent = item.remark || '';
			let disabled = false;
			let noticeLabel = '';
			let modalContext = '';
			let isShow = false;
			let clinicPhone1 = '';
			let clinicPhone2 = '';
			// 處理 disabled && 過診就不可以電話洽詢的情境
			let canContactByPhone = true;
			if (item.canRegister === 'Y' && item.limit as number >= 0) {
				isShow = true;
				if (isValidName(item.substituteDoctorName) && isValidName(item.substituteDoctorCode) && item.specialHandle !== '輪診') {
					toolTipContent = `代原${item.doctorName} ${item.remark}`;
					doctorName = item.substituteDoctorName;
				}
				if (item.specialHandle === '輪診') {
					doctorName = item.substituteDoctorName?.replace(/\(.*?\)/, '').trim();
				}
			} else if (item.canRegister === 'N') {
				disabled = true;
				if (item.specialHandle === '輪診') {
					doctorName = item.substituteDoctorName?.replace(/\(.*?\)/, '').trim();
				}
				if (item.medicalTreatmentNumber === '當班不開放掛號' && notContainKeyword(item.doctorName || '')) {
					if (isValidName(item.substituteDoctorName) && isValidName(item.substituteDoctorCode)) {
						doctorName = item.substituteDoctorName?.replace(/\(.*?\)/, '').trim();
						toolTipContent = 
						item.remark === '' ? `代原${item.doctorName} 目前掛號人數「${item.registeredNumber}人」。` : `代原${item.doctorName} ${item.remark}。目前掛號人數「${item.registeredNumber}人」。`;
						
					} else {
						toolTipContent = `${item.remark}`;
					}
					noticeLabel = '當班';
					modalContext = '當班已不開放線上預約，請來電洽詢。';
					isShow = true;
				}
				else if (item.medicalTreatmentNumber === '醫師請假' && item.limit === -2 && notContainKeyword(item.doctorName|| '') && item.specialHandle === '') {
					noticeLabel = '請假';
					canContactByPhone = false;
					isShow = true;
				}

				else if (item.medicalTreatmentNumber === '不接受網掛預約' && item.limit === -1) {
					// 備註有單週雙週判斷
					if (item.specialHandle === '' && includeKeywords(item.remark || '')) {
						noticeLabel = item.medicalTreatmentNumber;
						modalContext = '此診不接受網路預約，請您來電洽詢，謝謝。';
						isShow = true;
					} 
					
					else if (notContainKeyword(item.doctorName || '') && item.remark === '') {
						noticeLabel = item.medicalTreatmentNumber;
						modalContext = '此診不接受網路預約，請您來電洽詢，謝謝。';
						isShow = true;
					}

					else if (item.remark?.includes('特約門診') || item.remark?.includes('特別門診')) {
						noticeLabel = item.medicalTreatmentNumber;
						toolTipContent = `${item.remark}`;
						// canContactByPhone = false;
						modalContext = '特約門診請來電預約，謝謝。';
						isShow = true;
					}
				}

				else if (item.medicalTreatmentNumber === '不接受網掛預約' && (item.remark?.includes('特約門診') || item.remark?.includes('特別門診'))) {
					noticeLabel = '特約門診';
					modalContext = '特約門診請來電預約，謝謝。';
					isShow = true;
				}
				else if (item.medicalTreatmentNumber === '已超過掛號總人數' && item.limit === -3 ) {
					if (item.remark?.includes('特約門診') || item.remark?.includes('特別門診')) {
						noticeLabel = '預約額滿';
						toolTipContent = '';
						canContactByPhone = false;
						isShow = true;
					} else if (item.remark === '已超過掛號總人數') {
						noticeLabel = '預約額滿';
						modalContext = '此診不接受網路預約，請您來電洽詢，謝謝。';
						isShow = true;
					}
					
				}
				else if (item.medicalTreatmentNumber === '預掛額滿' && notContainKeyword(item.doctorName || '') && item.remark === '') {
					noticeLabel = '預約額滿';
					modalContext = '此診不接受網路預約，請您來電洽詢，謝謝。';
					isShow = true;
				}
			}

			if (item.specialHandle === 'APP顯示' && (isValidName(item.substituteDoctorName)) && (isValidName(item.substituteDoctorCode))) {
				isShow = true;
				noticeLabel = `${item.medicalTreatmentNumber}`;
				doctorName = item.substituteDoctorName?.replace(/\(.*?\)/, '').trim();
				toolTipContent = `代原${item.doctorName?.replace(/\(.*?\)/, '').trim()}醫師 ${item.remark}`;
			}
			else if (item.medicalTreatmentNumber === '不接受網掛預約' && item.specialHandle === 'APP顯示') {
				isShow = true;
				noticeLabel = item.medicalTreatmentNumber;
				modalContext = modalContext === '' ? '此診不接受網路預約，請您來電洽詢，謝謝。' : modalContext;
			}
			else if (item.specialHandle === 'APP顯示') {
				isShow = true;
				noticeLabel = '預約額滿';
			}

			// selectedBranch?.value?.clinics?.forEach((clinic: RawHospitalClinicResource) => {
			// 	if (clinic.code === item.hospitalClinicCode) {
			// 		clinicPhone1 = clinic.phone_1 || '';
			// 		clinicPhone2 = clinic.phone_2 || '';
			// 	}
			// });
			clinicPhone1 = item.phone_1 || '';
			clinicPhone2 = item.phone_2 || '';
			if (isShow) {
				newOutpatientList.push({
					...item,
					doctorName,
					toolTipContent,
					disabled,
					noticeLabel,
					modalContext,
					id: index,
					departmentContext: selectedDepartment?.value?.name || '',
					clinicPhone1,
					clinicPhone2,
					canContactByPhone,
				})
			}
		});
		console.log('newOutpatientList', newOutpatientList);

		const groupOutpatientByDay = (list: ExtendedClinicScheduleResource[]) => {
			const { days } = weekDays[selectedWeekAndDay.weekId];
			const groupedByDay: GroupedData = {};

			for (let i = 0; i < 7; i += 1) {
				groupedByDay[i] = [];
			};

			// 產生日期與星期的對照表 {2024/04/10: 0, ...}
			const dateToDayMap = days.reduce<{ [key: string]: number }>((acc, curr) => {
				acc[curr.date] = curr.id;
				return acc;
			}, {});

			list.forEach(item => {
				if (item.date) {
					const dayId = dateToDayMap[item.date];
					if (dayId !== undefined) {
						groupedByDay[dayId].push(item);
					}
				}
			});
			return groupedByDay;
		};
		const groupedData = groupOutpatientByDay(newOutpatientList);

		// 處理當日已過門診時段的情況
		const updateListByTimePeriod = (list: ExtendedClinicScheduleResource[]) => {
			const now = dayjs();
			const currentTime = now.format("HH:mm");
			const morningEnd = "12:30";
			const afternoonEnd = "17:00";
			const eveningEnd = "21:30";

			return list.map(item => {
				if (currentTime >= morningEnd && currentTime < afternoonEnd) {
					if (item.timePeriod === '早診') {
						item.noticeLabel = '已過診';
						item.canContactByPhone = false;
					}
				} else if (currentTime >= afternoonEnd && currentTime < eveningEnd) {
					if (item.timePeriod === '早診' || item.timePeriod === '午診') {
						item.noticeLabel = '已過診';
						item.canContactByPhone = false;
					}
				} else if (currentTime >= eveningEnd) {
					item.noticeLabel = '已過診';
					item.canContactByPhone = false;
				}
				return item;
			});
		};

		if (selectedWeekAndDay.weekId === 0) {
			const updatedGroupData = {
				...groupedData,
				'0': updateListByTimePeriod(groupedData['0'])
			};
			return {
				[selectedWeekAndDay.weekId]: updatedGroupData
			};
		}
		const updatedOutpatientList = {
			...outpatientList,
			[selectedWeekAndDay.weekId]: groupedData
		};

		return updatedOutpatientList;
	});

export const submitOnlineRegistration = createAction(
	'SUBMIT_ONLINE_REGISTRATION',
	(note: string) => async (dispatch: Dispatch, getState: GetState) => {
		const { v1UserOnlineRegistrationUpdate } = api;
		const { outpatientRegistration: { selectedOutpatient } } = getState();
		const params = {
			hospital_clinic_code: selectedOutpatient.hospitalClinicCode || '',
			date: selectedOutpatient.date || '',
			schedule_code: selectedOutpatient.scheduleCode || '',
			note,
		};
		try {
			const { status } = await v1UserOnlineRegistrationUpdate(params);

			if (status === 200) {
				advanceToNextStep({ result: REGISTRATION_RESULT_QUERY.SUCCESS, note });
			}
		} catch (err) {
			console.log('submitOnlineRegistration error', err);
			const { status, error } = err as { status: number, error: any };
			if (status === 401) {
				dispatch(updateAccessToken(''));
				history.push('/');
			} else if (status === 400) {
				advanceToNextStep({ result: REGISTRATION_RESULT_QUERY.FAILED, message: error.message.message });
			} else if (status === 500) {
				history.push(
					`${ROUTE_PATHS.REGISTRATION_FAILURE}`,
				);
			}
		}
		return null;
	},
);

export const getDeptFormUrl = createAction(
	'GET_DEPT_FORM_URL',
	() => async (dispatch: Dispatch, getState: GetState) => {
		const { v1GetDeptFormUrl } = api;

		try {
			const { doctorDepartmentRegistration: { selectedDepartment } } = getState();
			let params = {
				dept: 'basic'
			}

			if (selectedDepartment.value.iconCode === 'OBGYN') {
				params = {
					...params,
					dept: 'gyn'
				}
			} else if (selectedDepartment.value.iconCode === 'PED') {
				params = {
					...params,
					dept: 'paediatrics'
				}
			} else if (selectedDepartment.value.iconCode === 'DEN') {
				params = {
					...params,
					dept: 'dentistry'
				}
			}

			const { data } = await v1GetDeptFormUrl(params);
				
			return data?.data.formUrl;

		} catch (e) {
			console.log('getBranchList error', e);

			return "";
		}
	},
);

export const clearSelectedOutpatientInfo = createAction('CLEAR_SELECTED_OUTPATIENT_INFO');

export const clearSelectedWeekAndDay = createAction('CLEAR_SELECTED_WEEK_AND_DAY');

const setSelectedOptionsToLocal = createAction('SET_SELECTED_OPTIONS_TO_LOCAL', () => (_:Dispatch, getState: GetState) => {
	const { doctorOutpatientRegistration: { selectedOutpatient } } = getState();

	setItemToStorage(STORAGE_KEY.SELECTED_OUTPATIENT, selectedOutpatient, StorageType.LOCAL);
});

export const reducer = {
	// Workaround: HandleActions 目前定義無法支援多種 action 形式
	doctorOutpatientRegistration: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_OUTPATIENT_REGISTRATION_LIST_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_OUTPATIENT_REGISTRATION_LIST_FULFILLED: (state, action: Action<ListData>) => ({
				...state,
				loading: false,
				outpatientList: action.payload,
			}),
			INIT_WEEK_DAY_DATA: (
				state,
				action: Action<WeekDaysType[]>,
			) => ({
				...state,
				weekDays: action.payload,
			}),
			SET_SELECTED_WEEK_AND_DAY: (state, action) => ({
				...state,
				selectedWeekAndDay: action.payload,
			}),
			SET_SELECTED_OUTPATIENT: (state, action) => ({
				...state,
				selectedOutpatient: action.payload,
			}),
			SET_SELECTED_OUTPATIENT_DAY: (state, action) => ({
				...state,
				selectedOutpatientDay: action.payload,
			}),
			CLEAR_SELECTED_OUTPATIENT_INFO: state => ({
				...state,
				selectedOutpatientDay: '',
				selectedOutpatient: {} as ExtendedClinicScheduleResource,
				outpatientList: defaultState.outpatientList,
			}),
			CLEAR_SELECTED_WEEK_AND_DAY: state => ({
				...state,
				selectedWeekAndDay: defaultState.selectedWeekAndDay,
			}),
			GET_DEPT_FORM_URL_PENDING: state => ({
				...state,
				// loading: true,
			}),
			GET_DEPT_FORM_URL_FULFILLED: (state, action: Action<any>) => ({
				...state,
				// loading: false,
				formUrl: action.payload,
			}),
		},
		defaultState,
	),
};

const doctorOutpatientRegistrationActionsMap = {
	initWeekDayData,
	setSelectedWeekAndDay,
	getOutpatientRegistrationList,
	setSelectedOutpatient,
	setSelectedOutpatientDay,
	submitOnlineRegistration,
	clearSelectedOutpatientInfo,
	clearSelectedWeekAndDay,
	getDeptFormUrl,
	setSelectedOptionsToLocal
};

const mapHooksToState = (state: GlobalState) => ({
	weekDays: state.doctorOutpatientRegistration.weekDays,
	selectedWeekAndDay: state.doctorOutpatientRegistration.selectedWeekAndDay,
	selectedDepartment: state.doctorDepartmentRegistration.selectedDepartment,
	selectedBranch: state.doctorDepartmentRegistration.selectedBranch,
	outpatientList: state.doctorOutpatientRegistration.outpatientList,
	selectedOutpatient: state.doctorOutpatientRegistration.selectedOutpatient,
	loading: state.doctorOutpatientRegistration.loading,
	selectedOutpatientDay: state.doctorOutpatientRegistration.selectedOutpatientDay,
	formUrl: state.doctorOutpatientRegistration.formUrl,
});

type DoctorOutpatientRegistrationSelector = ReturnType<typeof mapHooksToState>;
type DoctorOutpatientRegistrationActionsMap = typeof doctorOutpatientRegistrationActionsMap;

export const useDoctorOutpatientRegistration = () =>
	useRedux<DoctorOutpatientRegistrationSelector, DoctorOutpatientRegistrationActionsMap>(
		mapHooksToState,
		doctorOutpatientRegistrationActionsMap,
	);
