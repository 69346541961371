import React, { useState } from 'react';
import classnames from 'classnames';
import history from 'store/history';

import { useAuth } from 'models/auth';
import { ROUTE_PATHS } from 'models/sideMenu';

import DropdownAvatar from 'components/molecules/DropdownAvatar';
// import Label from 'components/atoms/Label';
import { AvatarGender } from 'components/atoms/Avatar';
import SideMenu from 'components/molecules/SideMenu';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import Button from 'components/atoms/Button';

import useMedia from 'util/hook/useMedia';
import useBreadcrumbs from 'util/hook/useBreadcrumbs';
import { useUser } from 'models/user';

import LogoIcon from 'images/icon/logo.inline.svg';
import MenuIcon from 'images/icon/menu.inline.svg';
import CloseIcon from 'images/icon/close.inline.svg';
import {
	useDoctorOutpatientRegistration,
} from 'models/doctorOutpatientRegistration';

import styles from './index.module.css';

interface HeaderProperty {
	className?: string;
}

interface MobileHeaderProperty {
	className?: string;
}

const MobileHeader: React.FC<MobileHeaderProperty> = ({ className }) => {
	const [{ userInfo }] = useUser();
	const [{ isWebview }] = useAuth();
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const isLogin = Object.keys(userInfo).length > 0

	return (
		<div className={styles.mobileWrapper}>
			<header className={classnames(styles.mobileHeader, isMenuOpen && styles.close, className)}>
				<div className={styles.content}>
					{!isWebview && isLogin && (
						<button
							className={classnames(styles.menuTrigger, isMenuOpen && styles.close)}
							onClick={() => setIsMenuOpen(!isMenuOpen)}
							type="button"
						>
							{isMenuOpen ? <CloseIcon /> : <MenuIcon />}
						</button>
					)}
					<LogoIcon />
					<h3 className={styles.title}>禾馨醫療</h3>
					{/* <Label context="安和院區" /> */}
				</div>
				{!isMenuOpen && isLogin && (
					<DropdownAvatar
						name={userInfo.name || ''}
						gender={userInfo.gender as AvatarGender}
						identity="normal"
					/>
				)}
			</header>
			{isMenuOpen && <SideMenu isMenuOpen={isMenuOpen} onMenuClick={() => setIsMenuOpen(false)} />}
		</div>
	);
};

const Header: React.FC<HeaderProperty> = ({ className }) => {
	const [{ userInfo }] = useUser();
	const media = useMedia();
	const [{ breadcrumbs }] = useBreadcrumbs();
	const isLogin = Object.keys(userInfo).length > 0
	const [
		{},
		{
			clearSelectedOutpatientInfo,
		},
	] = useDoctorOutpatientRegistration();

	return media === 'mobile' ? (
		<MobileHeader />
	) : (
		<header className={classnames(styles.header, className)}>
			<Breadcrumbs crumbs={breadcrumbs} />
			<div className={styles.rightWrapper}>
				<Button 
					onClick={() => {
						clearSelectedOutpatientInfo();
						history.push('/doctor-registration?step=1');
					}}
					className={styles.buttonRed}
				>
					尋找醫師
				</Button>
				<Button 
					onClick={() => history.push(`/${ROUTE_PATHS.APPOINTMENTS}`)} className={styles.button}
				>
					預約及掛號
				</Button>
				{isLogin && (
					<DropdownAvatar
						name={userInfo.name || ''}
						gender={userInfo.gender as AvatarGender}
						identity="normal"
					/>
				)}
			</div>
		</header>
	);
};

export default Header;
