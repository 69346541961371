import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';
import { initWeekDayData } from 'models/outpatientRegistration';
import { getDepartmentList, getDoctorList, clearSearchDoctorList } from 'models/doctorDepartmentRegistration';


const routes: CustomRoute = {
	path: '/doctor-registration',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ next }) => {
    console.log(1)
		const children = await next();
		return children;
	},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'appointments' */ './component')],
			render: ([DoctorRegistration]) => <DoctorRegistration />,
			onEnter: async ({ next, store }) => {
				console.log('on Enter doctorRegistration');
				store.dispatch(clearSearchDoctorList());
				store.dispatch(initWeekDayData());
				await store.dispatch(getDepartmentList());
				// await store.dispatch(getDoctorList());
				console.log('on Enter doctorRegistration / end');
				const children = await next();
				return children;
			},
		},
	],
};

export default routes;
