/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
	AboutUsCategoryResource,
	AboutUsResource,
	AdminUserResource,
	AdvanceSearchable,
	AdvanceSearchRequest,
	AestheticMedicineAppointmentListResource,
	ApiErrorMessageResource,
	ApiResponseTemplate,
	BannerResource,
	BscoreBscoreAdminUserInitAccountCreateRequestPayload,
	BscoreBscoreAdminUserUnlockAccountCreateRequestPayload,
	BscoreDestroyAllCreateRequestPayload,
	BscoreExcelImportExampleFileCreateRequestPayload,
	BscoreExcelImportUploadUrlCreateRequestPayload,
	BscoreExportResult,
	BscoreForgetPasswordCreateRequestPayload,
	BscoreGetForgetMailInfoListRequestParams,
	BscoreGetUnlockMailInfoListRequestParams,
	BscoreGetVerifyMailInfoListRequestParams,
	BscoreImportResult,
	BscoreLoginCreateRequestPayload,
	BscoreResetPasswordCreateRequestPayload,
	BscoreResetPasswordFromMailCreateRequestPayload,
	BscoreSettingsUpdateRequestPayload,
	BscoreSubListDestroyAllCreateRequestPayload,
	BscoreSubListExcelImportExampleFileCreateRequestPayload,
	BscoreSubListExcelImportUploadUrlCreateRequestPayload,
	BscoreSubListUpdateListCreateRequestParams,
	BscoreSubListUpdateListCreateRequestPayload,
	BscoreSubListUpdateRowCreateRequestPayload,
	BscoreUnlockAccountCreateRequestPayload,
	BscoreUpdateListCreateRequestParams,
	BscoreUpdateListCreateRequestPayload,
	BscoreUpdateRowCreateRequestPayload,
	BscoreUploadUploadUrlCreateRequestPayload,
	BscoreVerifyAccountCreateRequestPayload,
	ClinicProgressResource,
	ClinicScheduleResource,
	ExcelSearchRequest,
	GetDataCreate,
	GetDataUpdate,
	GetListDataPaginator,
	HospitalBrancheDepartmentRefResource,
	HospitalBranchResource,
	HospitalClinicResource,
	HospitalDepartmentResource,
	HospitalDistrictBranchesResource,
	HospitalizationListResource,
	HospitalLocationResource,
	ImportRequest,
	LocationMapResource,
	MenuItemResource,
	MenuResource,
	NewbornHospitalizationListResource,
	NewsListResource,
	NewsResource,
	OnlineRegisterResource,
	OnlineRegistrationRecordResource,
	OperatingRoomAppointmentListResource,
	ParentBabyBindingResource,
	PostpartumMealResource,
	SearchRequest,
	StaffPermissionsResource,
	UserInspectionReportResource,
	UserResource,
	V1AboutUsCategoriesShowListRequestParams,
	V1AuthLoginCreateRequestPayload,
	V1AuthSendPasswordCreateRequestPayload,
	V1AuthSignUpCreateRequestPayload,
	V1AuthVerifyUserIdentityCreateRequestPayload,
	V1FirstDiagnosisInfoUpdateRequestParams,
	V1HisUserUpdateRequestParams,
	V1HospitalBrancheDepartmentRefListRequestParams,
	V1HospitalClinicProgressListRequestParams,
	V1HospitalClinicProgressUpdateRequestPayload,
	V1LibrariesMitakeSendCreateRequestParams,
	V1LibrariesMitakeStatusListRequestParams,
	V1StaffHospitalizationListListRequestParams,
	V1StaffIntrapartumFetalHeartRateAssessmentPageListRequestParams,
	V1StaffNewbornHospitalizationListListRequestParams,
	V1StaffNewbornInspectionReportListRequestParams,
	V1UserBabyPatientNoByMomListRequestParams,
	V1UserImageReportListRequestParams,
	V1UserInspectionReportListRequestParams,
	V1UserOnlineRegistrationCancelUpdateRequestParams,
	V1UserOnlineRegistrationClinicScheduleListRequestParams,
	V1UserOnlineRegistrationRecordListRequestParams,
	V1UserOnlineRegistrationUpdateRequestParams,
	V1UserParentBabyBindingCheckBabyCanBindListRequestParams,
	V1UserParentBabyBindingCreateCreateRequestPayload,
	V1UserParentBabyBindingSendVerificationCodeCreateRequestPayload,
	V1UserParentBabyBindingVerifyVerificationCodeCreateRequestParams,
	V1UserPasswordUpdateRequestPayload,
	V1UserSingleFirstDiagnosisListRequestParams,
	V1UserSwitchParentChildAccountUpdateRequestParams,
	V1UserUpdateRequestParams,
	EyeCheckImageListRequestParams,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Api<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>;

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http;
	}

	/**
	 * No description
	 *
	 * @tags About-Us
	 * @name V1AboutUsCategoriesList
	 * @summary 取得關於禾馨分類列表
	 * @request GET:/api/v1/about-us/categories
	 */
	v1AboutUsCategoriesList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: AboutUsCategoryResource;
				};
			},
			any
		>({
			path: `/api/v1/about-us/categories`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags About-Us
	 * @name V1AboutUsCategoriesShowList
	 * @summary 根據分類取得關於禾馨列表
	 * @request GET:/api/v1/about-us/categories/show
	 */
	v1AboutUsCategoriesShowList = (
		query: V1AboutUsCategoriesShowListRequestParams,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: AboutUsResource;
				};
			},
			{
				/** @example 422 */
				status?: number;
				/** @example "The given data was invalid." */
				message?: string;
				/** @example "validation_error" */
				error_code?: string;
				extra?: {
					categories_id?: string[];
				};
			}
		>({
			path: `/api/v1/about-us/categories/show`,
			method: 'GET',
			query: query,
			format: 'json',
			...params,
		});
	/**
	 * @description 取得禾馨月子餐
	 *
	 * @tags About-Us
	 * @name V1AboutUsPostpartumMealList
	 * @summary 取得禾馨月子餐
	 * @request GET:/api/v1/about-us/postpartum-meal
	 */
	v1AboutUsPostpartumMealList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: PostpartumMealResource;
				};
			},
			any
		>({
			path: `/api/v1/about-us/postpartum-meal`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Api-Error-Message
	 * @name V1ApiErrorMessageList
	 * @summary 取得 Api Error Message 資訊
	 * @request GET:/api/v1/api-error-message
	 */
	v1ApiErrorMessageList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: ApiErrorMessageResource[];
				};
			},
			any
		>({
			path: `/api/v1/api-error-message`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Banner
	 * @name V1BannerList
	 * @summary 取得 Banner 資訊
	 * @request GET:/api/v1/banner
	 */
	v1BannerList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: BannerResource[];
				};
			},
			any
		>({
			path: `/api/v1/banner`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 取得看診進度
	 *
	 * @tags Hospital
	 * @name V1HospitalClinicProgressList
	 * @summary 取得看診進度
	 * @request GET:/api/v1/hospital/clinic-progress
	 */
	v1HospitalClinicProgressList = (
		query: V1HospitalClinicProgressListRequestParams,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: ClinicProgressResource[];
				};
			},
			any
		>({
			path: `/api/v1/hospital/clinic-progress`,
			method: 'GET',
			query: query,
			format: 'json',
			...params,
		});
	/**
	 * @description 新增看診進度
	 *
	 * @tags Hospital
	 * @name V1HospitalClinicProgressUpdate
	 * @summary 新增/更新看診進度
	 * @request PUT:/api/v1/hospital/clinic-progress
	 * @secure
	 */
	v1HospitalClinicProgressUpdate = (
		data: V1HospitalClinicProgressUpdateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/v1/hospital/clinic-progress`,
			method: 'PUT',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Dianthus-Staff
	 * @name V1StaffOperatingRoomAppointmentListList
	 * @summary 取得開刀房預約清單
	 * @request GET:/api/v1/staff/operating-room-appointment-list
	 * @secure
	 */
	v1StaffOperatingRoomAppointmentListList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					/** 開刀房預約資料 */
					data?: OperatingRoomAppointmentListResource;
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/staff/operating-room-appointment-list`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Dianthus-Staff
	 * @name V1StaffAestheticMedicineAppointmentListList
	 * @summary 取得醫美預約清單
	 * @request GET:/api/v1/staff/aesthetic-medicine-appointment-list
	 * @secure
	 */
	v1StaffAestheticMedicineAppointmentListList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					/** 醫美預約資料 */
					data?: AestheticMedicineAppointmentListResource;
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/staff/aesthetic-medicine-appointment-list`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Dianthus-Staff
	 * @name V1StaffHospitalizationListList
	 * @summary 取得住院清單
	 * @request GET:/api/v1/staff/hospitalization-list
	 * @secure
	 */
	v1StaffHospitalizationListList = (
		query: V1StaffHospitalizationListListRequestParams,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					/** 住院資料 */
					data?: HospitalizationListResource;
				};
			},
			| BscoreImportResult
			| {
					/** @example 422 */
					status?: number;
					/** @example "The given data was invalid." */
					message?: string;
					/** @example "validation_error" */
					error_code?: string;
					extra?: {
						hospital_branch?: string[];
					};
			  }
		>({
			path: `/api/v1/staff/hospitalization-list`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Dianthus-Staff
	 * @name V1StaffNewbornHospitalizationListList
	 * @summary 取得新生兒住院清單
	 * @request GET:/api/v1/staff/newborn-hospitalization-list
	 * @secure
	 */
	v1StaffNewbornHospitalizationListList = (
		query: V1StaffNewbornHospitalizationListListRequestParams,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					/** 新生兒住院資料 */
					data?: NewbornHospitalizationListResource;
				};
			},
			| BscoreImportResult
			| {
					/** @example 422 */
					status?: number;
					/** @example "The given data was invalid." */
					message?: string;
					/** @example "validation_error" */
					error_code?: string;
					extra?: {
						hospital_branch?: string[];
					};
			  }
		>({
			path: `/api/v1/staff/newborn-hospitalization-list`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Dianthus-Staff
	 * @name V1StaffNewbornInspectionReportList
	 * @summary 取得新生兒檢驗報告
	 * @request GET:/api/v1/staff/newborn-inspection-report
	 * @secure
	 */
	v1StaffNewbornInspectionReportList = (
		query: V1StaffNewbornInspectionReportListRequestParams,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					/** 檢驗資料 */
					data?: {
						/** 檢驗名稱 */
						name?: string;
						/** 檢驗日期 */
						date?: string;
						/** 檢驗結果 */
						result?: string;
						/** 備註 */
						memo?: string;
					}[];
				};
			},
			| BscoreImportResult
			| {
					/** @example 422 */
					status?: number;
					/** @example "The given data was invalid." */
					message?: string;
					/** @example "validation_error" */
					error_code?: string;
					extra?: {
						hospital_branch?: string[];
					};
			  }
		>({
			path: `/api/v1/staff/newborn-inspection-report`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Dianthus-Staff
	 * @name V1StaffDoctorListList
	 * @summary 取得醫生清單
	 * @request GET:/api/v1/staff/doctor-list
	 * @secure
	 */
	v1StaffDoctorListList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					/** 醫生清單 */
					data?: {
						/** 醫生名 */
						name?: string;
					}[];
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/staff/doctor-list`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Dianthus-Staff
	 * @name V1StaffOperatingRoomListList
	 * @summary 取得開刀房清單
	 * @request GET:/api/v1/staff/operating-room-list
	 * @secure
	 */
	v1StaffOperatingRoomListList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					/** 開刀房清單 */
					data?: {
						/** 開刀房名 */
						name?: string;
					}[];
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/staff/operating-room-list`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Dianthus-Staff
	 * @name V1StaffIntrapartumFetalHeartRateAssessmentPageList
	 * @summary 取得胎心音頁面
	 * @request GET:/api/v1/staff/intrapartum-fetal-heart-rate-assessment-page
	 * @secure
	 */
	v1StaffIntrapartumFetalHeartRateAssessmentPageList = (
		query: V1StaffIntrapartumFetalHeartRateAssessmentPageListRequestParams,
		params: RequestParams = {},
	) =>
		this.http.request<
			any,
			| void
			| BscoreImportResult
			| {
					/** @example 422 */
					status?: number;
					/** @example "The given data was invalid." */
					message?: string;
					/** @example "validation_error" */
					error_code?: string;
					extra?: {
						hospital_branch?: string[];
					};
			  }
		>({
			path: `/api/v1/staff/intrapartum-fetal-heart-rate-assessment-page`,
			method: 'GET',
			query: query,
			secure: true,
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Dianthus-User
	 * @name V1UserImageReportList
	 * @summary 取得使用者影像報告
	 * @request GET:/api/v1/user/image-report
	 * @secure
	 */
	v1UserImageReportList = (query: V1UserImageReportListRequestParams, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** 第三方 API 回傳資料 */
						aps?: {
							/** 影像檔案虛擬目錄 */
							url?: string;
							/** 檢查影像紀錄 */
							record?: {
								/** StudyInstanceUID編碼 */
								uid?: string;
								/** 檢查日期，格式yyyymmdd */
								date?: string;
								/** 醫師名稱 */
								dr?: string;
								/** 檢查說明 */
								description?: string;
								/** 靜態圖像部分路徑 */
								photo?: any[];
								/** 靜態影像部分路徑 */
								video?: any[];
							}[];
						};
					};
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/user/image-report`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Dianthus-User
	 * @name V1UserInspectionReportList
	 * @summary 取得使用者檢驗報告（個案查詢）
	 * @request GET:/api/v1/user/inspection-report
	 * @secure
	 */
	v1UserInspectionReportList = (
		query: V1UserInspectionReportListRequestParams,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: UserInspectionReportResource;
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/user/inspection-report`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Dianthus-User
	 * @name V1UserBabyPatientNoByMomList
	 * @summary 用媽媽病歷號取得寶寶病歷號
	 * @request GET:/api/v1/user/baby-patient-no-by-mom
	 * @secure
	 */
	v1UserBabyPatientNoByMomList = (
		query: V1UserBabyPatientNoByMomListRequestParams,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						patientNo?: {
							/** 媽媽病歷號 */
							mother?: string;
							/** 寶寶資料 */
							baby?: {
								/** 預產日 */
								eDC?: string;
								/** 寶寶病歷號 */
								patientNo?: string;
							}[];
						};
					};
				};
			},
			| BscoreImportResult
			| {
					/** @example 422 */
					status?: number;
					/** @example "The given data was invalid." */
					message?: string;
					/** @example "validation_error" */
					error_code?: string;
					extra?: {
						hospital_branch?: string[];
					};
			  }
		>({
			path: `/api/v1/user/baby-patient-no-by-mom`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Dianthus-User
	 * @name V1UserSingleFirstDiagnosisList
	 * @summary 查詢是否初診
	 * @request GET:/api/v1/user/single-first-diagnosis
	 * @secure
	 */
	v1UserSingleFirstDiagnosisList = (
		query: V1UserSingleFirstDiagnosisListRequestParams,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** 轉拋 API 回傳狀態 */
						success?: boolean;
						/** 訊息 */
						message?: string;
						/** 是否有填寫過初診單 (Y/N) */
						data?: string;
						/** 單一初診表單連結 */
						form_url?: string;
					};
				};
			},
			any
		>({
			path: `/api/v1/user/single-first-diagnosis`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Dianthus-User
	 * @name V1UserOnlineRegistrationClinicScheduleList
	 * @summary 取得診所排班資訊
	 * @request GET:/api/v1/online-registration/clinic-schedule
	 * @secure
	 */
	v1UserOnlineRegistrationClinicScheduleList = (
		query: V1UserOnlineRegistrationClinicScheduleListRequestParams,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: ClinicScheduleResource[];
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/online-registration/clinic-schedule`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Dianthus-User
	 * @name V1UserOnlineRegistrationRecordList
	 * @summary 取得掛號資訊
	 * @request GET:/api/v1/online-registration/record
	 * @secure
	 */
	v1UserOnlineRegistrationRecordList = (
		query: V1UserOnlineRegistrationRecordListRequestParams,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: OnlineRegistrationRecordResource[];
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/online-registration/record`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Dianthus-User
	 * @name V1UserOnlineRegistrationUpdate
	 * @summary 預約掛號
	 * @request PUT:/api/v1/online-registration
	 * @secure
	 */
	v1UserOnlineRegistrationUpdate = (
		query: V1UserOnlineRegistrationUpdateRequestParams,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: OnlineRegisterResource;
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/online-registration`,
			method: 'PUT',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Dianthus-User
	 * @name V1UserOnlineRegistrationCancelUpdate
	 * @summary 取消預約掛號
	 * @request PUT:/api/v1/online-registration/cancel
	 * @secure
	 */
	v1UserOnlineRegistrationCancelUpdate = (
		query: V1UserOnlineRegistrationCancelUpdateRequestParams,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, BscoreImportResult>({
			path: `/api/v1/online-registration/cancel`,
			method: 'PUT',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags First-Diagnosis
	 * @name V1FirstDiagnosisInfoUpdate
	 * @summary 禾馨更新我方全初診資料
	 * @request PUT:/api/v1/first-diagnosis/info
	 * @secure
	 */
	v1FirstDiagnosisInfoUpdate = (
		query: V1FirstDiagnosisInfoUpdateRequestParams,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, BscoreImportResult>({
			path: `/api/v1/first-diagnosis/info`,
			method: 'PUT',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags HIS
	 * @name V1HisUserUpdate
	 * @summary HIS 更新會員資訊
	 * @request PUT:/api/v1/his/user
	 * @secure
	 */
	v1HisUserUpdate = (query: V1HisUserUpdateRequestParams, params: RequestParams = {}) =>
		this.http.request<ApiResponseTemplate, BscoreImportResult>({
			path: `/api/v1/his/user`,
			method: 'PUT',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 取得禾馨據點診所資料
	 *
	 * @tags Hospital
	 * @name V1HospitalLocationList
	 * @summary 取得禾馨據點診所資料
	 * @request GET:/api/v1/hospital/location
	 */
	v1HospitalLocationList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: HospitalLocationResource[];
				};
			},
			any
		>({
			path: `/api/v1/hospital/location`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 取得禾馨據點地圖
	 *
	 * @tags Hospital
	 * @name V1HospitalLocationMapList
	 * @summary 取得禾馨據點地圖
	 * @request GET:/api/v1/hospital/location-map
	 */
	v1HospitalLocationMapList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: LocationMapResource;
				};
			},
			any
		>({
			path: `/api/v1/hospital/location-map`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 根據地區取得醫院資料
	 *
	 * @tags Hospital
	 * @name V1HospitalDistrictBranchesList
	 * @summary 根據地區取得醫院資料
	 * @request GET:/api/v1/hospital/district-branches
	 */
	v1HospitalDistrictBranchesList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: HospitalDistrictBranchesResource[];
				};
			},
			any
		>({
			path: `/api/v1/hospital/district-branches`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 取得院區清單
	 *
	 * @tags Hospital
	 * @name V1HospitalBrancheListList
	 * @summary 取得院區清單
	 * @request GET:/api/v1/hospital/branche-list
	 */
	v1HospitalBrancheListList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: HospitalBranchResource[];
				};
			},
			any
		>({
			path: `/api/v1/hospital/branche-list`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 取得科別清單
	 *
	 * @tags Hospital
	 * @name V1HospitalDepartmentListList
	 * @summary 取得科別清單
	 * @request GET:/api/v1/hospital/department-list
	 */
	v1HospitalDepartmentListList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: HospitalDepartmentResource[];
				};
			},
			any
		>({
			path: `/api/v1/hospital/department-list`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 透過院區ID/科別ID查詢符合的院區資料
	 *
	 * @tags Hospital
	 * @name V1HospitalBrancheDepartmentRefList
	 * @summary 院區/科別雙向查詢
	 * @request GET:/api/v1/hospital/branche-department-ref
	 */
	v1HospitalBrancheDepartmentRefList = (
		query: V1HospitalBrancheDepartmentRefListRequestParams,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: HospitalBrancheDepartmentRefResource[];
				};
			},
			any
		>({
			path: `/api/v1/hospital/branche-department-ref`,
			method: 'GET',
			query: query,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Hospital
	 * @name V1HospitalBrancheDetail
	 * @summary 取得院區內容
	 * @request GET:/api/v1/hospital/branche/{brancheId}
	 */
	v1HospitalBrancheDetail = (brancheId: number, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: HospitalBranchResource;
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/hospital/branche/${brancheId}`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Hospital
	 * @name V1HospitalClinicDetail
	 * @summary 取得診所內容
	 * @request GET:/api/v1/hospital/clinic/{code}
	 */
	v1HospitalClinicDetail = (code: string, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: HospitalClinicResource;
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/hospital/clinic/${code}`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Library-Test
	 * @name V1LibrariesMitakeSendCreate
	 * @summary 【後端內部測試用】三竹簡訊-發送簡訊
	 * @request POST:/api/v1/libraries/mitake/send
	 */
	v1LibrariesMitakeSendCreate = (
		query: V1LibrariesMitakeSendCreateRequestParams,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: object;
			},
			any
		>({
			path: `/api/v1/libraries/mitake/send`,
			method: 'POST',
			query: query,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Library-Test
	 * @name V1LibrariesMitakeStatusList
	 * @summary 【後端內部測試用】三竹簡訊-查詢簡訊發送狀態或餘額查詢
	 * @request GET:/api/v1/libraries/mitake/status
	 */
	v1LibrariesMitakeStatusList = (
		query: V1LibrariesMitakeStatusListRequestParams,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: object;
			},
			any
		>({
			path: `/api/v1/libraries/mitake/status`,
			method: 'GET',
			query: query,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags News
	 * @name V1NewsList
	 * @summary 取得最新消息列表
	 * @request GET:/api/v1/news
	 */
	v1NewsList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: NewsListResource;
				};
			},
			any
		>({
			path: `/api/v1/news`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags News
	 * @name V1NewsDetail
	 * @summary 取得最新消息內容
	 * @request GET:/api/v1/news/{newsId}
	 */
	v1NewsDetail = (newsId: number, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: NewsResource;
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/news/${newsId}`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags ParentBabyBinding
	 * @name V1UserParentBabyBindingList
	 * @summary 取得親子歸附資料
	 * @request GET:/api/v1/user/parent-baby-binding
	 * @secure
	 */
	v1UserParentBabyBindingList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: ParentBabyBindingResource[];
			},
			BscoreImportResult
		>({
			path: `/api/v1/user/parent-baby-binding`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags ParentBabyBinding
	 * @name V1UserParentBabyBindingCreateCreate
	 * @summary 新增親子歸附資料
	 * @request POST:/api/v1/user/parent-baby-binding/create
	 * @secure
	 */
	v1UserParentBabyBindingCreateCreate = (
		data: V1UserParentBabyBindingCreateCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** 綁定成功 */
						message?: string;
					};
				};
			},
			| BscoreImportResult
			| {
					/** @example 422 */
					status?: number;
					/** @example "The given data was invalid." */
					message?: string;
					/** @example "validation_error" */
					error_code?: string;
					extra?: {
						twid?: string[];
					};
			  }
		>({
			path: `/api/v1/user/parent-baby-binding/create`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags ParentBabyBinding
	 * @name V1UserParentBabyBindingCheckBabyCanBindList
	 * @summary 驗證寶寶資格
	 * @request GET:/api/v1/user/parent-baby-binding/check-baby-can-bind
	 * @secure
	 */
	v1UserParentBabyBindingCheckBabyCanBindList = (
		query: V1UserParentBabyBindingCheckBabyCanBindListRequestParams,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** 是否能綁定 */
						can_bind?: boolean;
					};
				};
			},
			| BscoreImportResult
			| {
					/** @example 422 */
					status?: number;
					/** @example "The given data was invalid." */
					message?: string;
					/** @example "validation_error" */
					error_code?: string;
					extra?: {
						twid?: string[];
					};
			  }
		>({
			path: `/api/v1/user/parent-baby-binding/check-baby-can-bind`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags ParentBabyBinding
	 * @name V1UserParentBabyBindingSendVerificationCodeCreate
	 * @summary 寄送親子歸附驗證碼
	 * @request POST:/api/v1/user/parent-baby-binding/send-verification-code
	 * @secure
	 */
	v1UserParentBabyBindingSendVerificationCodeCreate = (
		data: V1UserParentBabyBindingSendVerificationCodeCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** 驗證碼已寄送 */
						message?: string;
					};
				};
			},
			| BscoreImportResult
			| {
					/** @example 422 */
					status?: number;
					/** @example "The given data was invalid." */
					message?: string;
					/** @example "validation_error" */
					error_code?: string;
					extra?: {
						type?: string[];
					};
			  }
		>({
			path: `/api/v1/user/parent-baby-binding/send-verification-code`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags ParentBabyBinding
	 * @name V1UserParentBabyBindingVerifyVerificationCodeCreate
	 * @summary 驗證親子歸附驗證碼
	 * @request POST:/api/v1/user/parent-baby-binding/verify-verification-code
	 * @secure
	 */
	v1UserParentBabyBindingVerifyVerificationCodeCreate = (
		query: V1UserParentBabyBindingVerifyVerificationCodeCreateRequestParams,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** 驗證結果 */
						result?: boolean;
					};
				};
			},
			| BscoreImportResult
			| {
					/** @example 422 */
					status?: number;
					/** @example "The given data was invalid." */
					message?: string;
					/** @example "validation_error" */
					error_code?: string;
					extra?: {
						type?: string[];
					};
			  }
		>({
			path: `/api/v1/user/parent-baby-binding/verify-verification-code`,
			method: 'POST',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Staff
	 * @name V1StaffPermissionsList
	 * @summary 取得員工專區權限
	 * @request GET:/api/v1/staff/permissions
	 * @secure
	 */
	v1StaffPermissionsList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: StaffPermissionsResource;
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/staff/permissions`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Auth
	 * @name V1AuthLoginCreate
	 * @summary 會員一般登入
	 * @request POST:/api/v1/auth/login
	 */
	v1AuthLoginCreate = (data: V1AuthLoginCreateRequestPayload, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example "eyJ0e..." */
						token?: string;
					};
				};
			},
			| BscoreImportResult
			| {
					/** @example 422 */
					status?: number;
					/** @example "The given data was invalid." */
					message?: string;
					/** @example "validation_error" */
					error_code?: string;
					extra?: {
						twid?: string[];
						password?: string[];
					};
			  }
		>({
			path: `/api/v1/auth/login`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Auth
	 * @name V1AuthRefreshTokenCreate
	 * @summary Refresh Token
	 * @request POST:/api/v1/auth/refresh-token
	 * @secure
	 */
	v1AuthRefreshTokenCreate = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						token?: string;
					};
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/auth/refresh-token`,
			method: 'POST',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Auth
	 * @name V1AuthLogoutCreate
	 * @summary 登出
	 * @request POST:/api/v1/auth/logout
	 * @secure
	 */
	v1AuthLogoutCreate = (params: RequestParams = {}) =>
		this.http.request<ApiResponseTemplate, BscoreImportResult>({
			path: `/api/v1/auth/logout`,
			method: 'POST',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Auth
	 * @name V1AuthSignUpCreate
	 * @summary 會員註冊
	 * @request POST:/api/v1/auth/sign-up
	 */
	v1AuthSignUpCreate = (data: V1AuthSignUpCreateRequestPayload, params: RequestParams = {}) =>
		this.http.request<ApiResponseTemplate, BscoreImportResult>({
			path: `/api/v1/auth/sign-up`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Auth
	 * @name V1AuthSendPasswordCreate
	 * @summary 寄送會員密碼
	 * @request POST:/api/v1/auth/send-password
	 */
	v1AuthSendPasswordCreate = (
		data: V1AuthSendPasswordCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, BscoreImportResult>({
			path: `/api/v1/auth/send-password`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Auth
	 * @name V1AuthVerifyUserIdentityCreate
	 * @summary 會員驗證身份
	 * @request POST:/api/v1/auth/verify-user-identity
	 */
	v1AuthVerifyUserIdentityCreate = (
		data: V1AuthVerifyUserIdentityCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** Email */
						email?: string;
						/** 手機號碼 */
						phone?: string;
					};
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/auth/verify-user-identity`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags User
	 * @name V1UserList
	 * @summary 取得會員資訊
	 * @request GET:/api/v1/user
	 * @secure
	 */
	v1UserList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: UserResource;
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/user`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags User
	 * @name V1UserUpdate
	 * @summary 更新會員資訊
	 * @request PUT:/api/v1/user
	 * @secure
	 */
	v1UserUpdate = (query: V1UserUpdateRequestParams, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: UserResource;
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/user`,
			method: 'PUT',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags User
	 * @name V1UserSwitchParentChildAccountUpdate
	 * @summary 切換親子帳號
	 * @request PUT:/api/v1/user/switch-parent-child-account
	 * @secure
	 */
	v1UserSwitchParentChildAccountUpdate = (
		query: V1UserSwitchParentChildAccountUpdateRequestParams,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example "eyJ0e..." */
						token?: string;
					};
				};
			},
			| BscoreImportResult
			| {
					/** @example 422 */
					status?: number;
					/** @example "The given data was invalid." */
					message?: string;
					/** @example "validation_error" */
					error_code?: string;
					extra?: {
						switch_type?: string[];
					};
			  }
		>({
			path: `/api/v1/user/switch-parent-child-account`,
			method: 'PUT',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags User
	 * @name V1UserPasswordUpdate
	 * @summary 更換會員密碼
	 * @request PUT:/api/v1/user/password
	 * @secure
	 */
	v1UserPasswordUpdate = (data: V1UserPasswordUpdateRequestPayload, params: RequestParams = {}) =>
		this.http.request<ApiResponseTemplate, BscoreImportResult>({
			path: `/api/v1/user/password`,
			method: 'PUT',
			body: data,
			secure: true,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags User
	 * @name V1UserAccessDisableUpdate
	 * @summary 會員停權
	 * @request PUT:/api/v1/user/access-disable
	 * @secure
	 */
	v1UserAccessDisableUpdate = (params: RequestParams = {}) =>
		this.http.request<ApiResponseTemplate, BscoreImportResult>({
			path: `/api/v1/user/access-disable`,
			method: 'PUT',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags User
	 * @name V1UserCheckFirstDiagnosisList
	 * @summary 查詢是否初診
	 * @request GET:/api/v1/user/check-first-diagnosis
	 * @secure
	 */
	v1UserCheckFirstDiagnosisList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/**
						 * 是否需要填寫初診單 (bool)
						 * @example "true"
						 */
						first_diagnosis?: boolean;
						/** 初診單連結 */
						form_url?: string;
					};
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/user/check-first-diagnosis`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 後台登入用，會取得 JWT Token
	 *
	 * @tags bscore_admin_auth
	 * @name BscoreLoginCreate
	 * @request POST:/api/bscore/login
	 */
	bscoreLoginCreate = (data: BscoreLoginCreateRequestPayload, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/**
						 * JWT token
						 * @example "eyJ0eXAiOiJ..."
						 */
						token?: string;
						/** 模組 route 列表，提供跳轉用 */
						default?: string[];
					};
				};
			},
			any
		>({
			path: `/api/bscore/login`,
			method: 'POST',
			body: data,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description Refresh Token
	 *
	 * @tags bscore_admin_auth
	 * @name BscoreRefreshTokenCreate
	 * @request POST:/api/bscore/refresh-token
	 * @secure
	 */
	bscoreRefreshTokenCreate = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/**
						 * JWT token
						 * @example "eyJ0eXAiOiJ..."
						 */
						token?: string;
					};
				};
			},
			any
		>({
			path: `/api/bscore/refresh-token`,
			method: 'POST',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 變更密碼
	 *
	 * @tags bscore_admin_auth
	 * @name BscoreResetPasswordCreate
	 * @request POST:/api/bscore/resetPassword
	 * @secure
	 */
	bscoreResetPasswordCreate = (
		data: BscoreResetPasswordCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/resetPassword`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_admin_user
	 * @name BscoreMeList
	 * @summary 取得目前使用者的資料
	 * @request GET:/api/bscore/me
	 * @secure
	 */
	bscoreMeList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: AdminUserResource;
				};
			},
			any
		>({
			path: `/api/bscore/me`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 重置使用者，並寄送驗證信
	 *
	 * @tags bscore_admin_user
	 * @name BscoreBscoreAdminUserInitAccountCreate
	 * @request POST:/api/bscore/bscore:admin-user/init-account
	 * @secure
	 */
	bscoreBscoreAdminUserInitAccountCreate = (
		adminUser: string,
		data: BscoreBscoreAdminUserInitAccountCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/bscore${adminUser}/init-account`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description 寄送帳號解鎖信
	 *
	 * @tags bscore_admin_user
	 * @name BscoreBscoreAdminUserUnlockAccountCreate
	 * @request POST:/api/bscore/bscore:admin-user/unlock-account
	 * @secure
	 */
	bscoreBscoreAdminUserUnlockAccountCreate = (
		adminUser: string,
		data: BscoreBscoreAdminUserUnlockAccountCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/bscore${adminUser}/unlock-account`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description 取得帳號解鎖信 user 資料
	 *
	 * @tags bscore_admin_user
	 * @name BscoreGetUnlockMailInfoList
	 * @request GET:/api/bscore/get-unlock-mail-info
	 */
	bscoreGetUnlockMailInfoList = (
		query: BscoreGetUnlockMailInfoListRequestParams,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: AdminUserResource;
				};
			},
			any
		>({
			path: `/api/bscore/get-unlock-mail-info`,
			method: 'GET',
			query: query,
			format: 'json',
			...params,
		});
	/**
	 * @description 解鎖帳號
	 *
	 * @tags bscore_admin_user
	 * @name BscoreUnlockAccountCreate
	 * @request POST:/api/bscore/unlock-account
	 */
	bscoreUnlockAccountCreate = (
		data: BscoreUnlockAccountCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/unlock-account`,
			method: 'POST',
			body: data,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description 取得信箱驗證信 user 資料
	 *
	 * @tags bscore_admin_user
	 * @name BscoreGetVerifyMailInfoList
	 * @request GET:/api/bscore/get-verify-mail-info
	 */
	bscoreGetVerifyMailInfoList = (
		query: BscoreGetVerifyMailInfoListRequestParams,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: AdminUserResource;
				};
			},
			any
		>({
			path: `/api/bscore/get-verify-mail-info`,
			method: 'GET',
			query: query,
			format: 'json',
			...params,
		});
	/**
	 * @description 驗證帳號
	 *
	 * @tags bscore_admin_user
	 * @name BscoreVerifyAccountCreate
	 * @request POST:/api/bscore/verify-account
	 */
	bscoreVerifyAccountCreate = (
		data: BscoreVerifyAccountCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/verify-account`,
			method: 'POST',
			body: data,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description 忘記密碼信
	 *
	 * @tags bscore_admin_user
	 * @name BscoreForgetPasswordCreate
	 * @request POST:/api/bscore/forget-password
	 */
	bscoreForgetPasswordCreate = (
		data: BscoreForgetPasswordCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						next_can_send?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/forget-password`,
			method: 'POST',
			body: data,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description 忘記密碼 - 重設密碼資訊
	 *
	 * @tags bscore_admin_user
	 * @name BscoreGetForgetMailInfoList
	 * @request GET:/api/bscore/get-forget-mail-info
	 */
	bscoreGetForgetMailInfoList = (
		query: BscoreGetForgetMailInfoListRequestParams,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: AdminUserResource;
				};
			},
			any
		>({
			path: `/api/bscore/get-forget-mail-info`,
			method: 'GET',
			query: query,
			format: 'json',
			...params,
		});
	/**
	 * @description 忘記密碼 - 重設密碼
	 *
	 * @tags bscore_admin_user
	 * @name BscoreResetPasswordFromMailCreate
	 * @request POST:/api/bscore/reset-password-from-mail
	 */
	bscoreResetPasswordFromMailCreate = (
		data: BscoreResetPasswordFromMailCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/reset-password-from-mail`,
			method: 'POST',
			body: data,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreGetModuleConfigDetail
	 * @summary 取得選單對應的模組 config
	 * @request GET:/api/bscore/{menu_item_key}/get-module-config
	 * @secure
	 */
	bscoreGetModuleConfigDetail = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<GetDataCreate, any>({
			path: `/api/bscore/${menuItemKey}/get-module-config`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreGetSearchableDetail
	 * @summary 取得進階搜尋欄位
	 * @request GET:/api/bscore/{menu_item_key}/get-searchable
	 * @secure
	 */
	bscoreGetSearchableDetail = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<AdvanceSearchable[], any>({
			path: `/api/bscore/${menuItemKey}/get-searchable`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 一般搜尋
	 *
	 * @tags bscore
	 * @name BscoreSearchCreate
	 * @request POST:/api/bscore/{menu_item_key}/search
	 * @secure
	 */
	bscoreSearchCreate = (menuItemKey: string, data: SearchRequest, params: RequestParams = {}) =>
		this.http.request<GetListDataPaginator, any>({
			path: `/api/bscore/${menuItemKey}/search`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreAdvanceSearchCreate
	 * @summary 進階搜尋
	 * @request POST:/api/bscore/{menu_item_key}/advance-search
	 * @secure
	 */
	bscoreAdvanceSearchCreate = (
		menuItemKey: string,
		data: AdvanceSearchRequest,
		params: RequestParams = {},
	) =>
		this.http.request<GetListDataPaginator, any>({
			path: `/api/bscore/${menuItemKey}/advance-search`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreCreateDetail
	 * @summary 新增資料頁顯示
	 * @request GET:/api/bscore/{menu_item_key}/create
	 * @secure
	 */
	bscoreCreateDetail = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<GetDataCreate, any>({
			path: `/api/bscore/${menuItemKey}/create`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreEditDetail
	 * @summary 編輯資料頁顯示
	 * @request GET:/api/bscore/{menu_item_key}/{id}/edit
	 * @secure
	 */
	bscoreEditDetail = (menuItemKey: string, id: string, params: RequestParams = {}) =>
		this.http.request<GetDataUpdate, any>({
			path: `/api/bscore/${menuItemKey}/${id}/edit`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreCreate
	 * @summary 新增
	 * @request POST:/api/bscore/{menu_item_key}
	 * @secure
	 */
	bscoreCreate = (menuItemKey: string, data: GetDataCreate, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						id?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreUpdate
	 * @summary 更新
	 * @request PUT:/api/bscore/{menu_item_key}/{id}
	 * @secure
	 */
	bscoreUpdate = (
		menuItemKey: string,
		id: string,
		data: GetDataUpdate,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						id?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/${id}`,
			method: 'PUT',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreDelete
	 * @summary 刪除
	 * @request DELETE:/api/bscore/{menu_item_key}/{id}
	 * @secure
	 */
	bscoreDelete = (menuItemKey: string, id: string, params: RequestParams = {}) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/${id}`,
			method: 'DELETE',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreCopyCreate
	 * @summary 複製
	 * @request POST:/api/bscore/{menu_item_key}/copy
	 * @secure
	 */
	bscoreCopyCreate = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						id?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/copy`,
			method: 'POST',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreDestroyAllCreate
	 * @summary 批次刪除
	 * @request POST:/api/bscore/{menu_item_key}/destroy-all
	 * @secure
	 */
	bscoreDestroyAllCreate = (
		menuItemKey: string,
		data: BscoreDestroyAllCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/destroy-all`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreUpdateRowCreate
	 * @summary 列表單一行更新
	 * @request POST:/api/bscore/{menu_item_key}/update-row
	 * @secure
	 */
	bscoreUpdateRowCreate = (
		menuItemKey: string,
		data: BscoreUpdateRowCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/update-row`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreUpdateListCreate
	 * @summary reorder/update status
	 * @request POST:/api/bscore/{menu_item_key}/update-list
	 * @secure
	 */
	bscoreUpdateListCreate = (
		{ menuItemKey, ...query }: BscoreUpdateListCreateRequestParams,
		data: BscoreUpdateListCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/update-list`,
			method: 'POST',
			query: query,
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description 取得 Menu Item 對應的 Dashboard Config
	 *
	 * @tags bscore_dashboard
	 * @name BscoreGetDashboardConfigDetail
	 * @request GET:/api/bscore/{menu_item_key}/get-dashboard-config
	 * @secure
	 */
	bscoreGetDashboardConfigDetail = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/get-dashboard-config`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 取得 Metric Key 對應的 Metric
	 *
	 * @tags bscore_dashboard
	 * @name BscoreMetricDetail
	 * @request GET:/api/bscore/metric/{metric_key}
	 * @secure
	 */
	bscoreMetricDetail = (metricKey: string, params: RequestParams = {}) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/metric/${metricKey}`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreExcelExportCreate
	 * @summary 匯出
	 * @request POST:/api/bscore/{menu_item_key}/excel-export
	 * @secure
	 */
	bscoreExcelExportCreate = (
		menuItemKey: string,
		data: ExcelSearchRequest,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						taskId?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/excel-export`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListExcelExportCreate
	 * @summary SubList 匯出
	 * @request POST:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/excel-export
	 * @secure
	 */
	bscoreSubListExcelExportCreate = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		data: ExcelSearchRequest,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						taskId?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/excel-export`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreExcelImportCreate
	 * @summary 匯入
	 * @request POST:/api/bscore/{menu_item_key}/excel-import
	 * @secure
	 */
	bscoreExcelImportCreate = (
		menuItemKey: string,
		data: ImportRequest,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						taskId?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/excel-import`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.FormData,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreExcelImportUploadUrlCreate
	 * @summary 取得匯入用上傳檔案網址
	 * @request POST:/api/bscore/{menu_item_key}/excel-import-upload-url
	 * @secure
	 */
	bscoreExcelImportUploadUrlCreate = (
		menuItemKey: string,
		data: BscoreExcelImportUploadUrlCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						url?: string;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/excel-import-upload-url`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListExcelImportCreate
	 * @summary SubList 匯入
	 * @request POST:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/excel-import
	 * @secure
	 */
	bscoreSubListExcelImportCreate = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		data: ImportRequest,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						taskId?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/excel-import`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.FormData,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListExcelImportUploadUrlCreate
	 * @summary 取得 SubList 匯入用上傳檔案網址
	 * @request POST:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/excel-import-upload-url
	 * @secure
	 */
	bscoreSubListExcelImportUploadUrlCreate = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		data: BscoreSubListExcelImportUploadUrlCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						url?: string;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/excel-import-upload-url`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreExcelImportExampleFileCreate
	 * @summary 下載匯入範例檔案
	 * @request POST:/api/bscore/{menu_item_key}/excel-import-example-file
	 * @secure
	 */
	bscoreExcelImportExampleFileCreate = (
		menuItemKey: string,
		data: BscoreExcelImportExampleFileCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<void, any>({
			path: `/api/bscore/${menuItemKey}/excel-import-example-file`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListExcelImportExampleFileCreate
	 * @summary 下載 SubList 匯入範例檔案
	 * @request POST:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/excel-import-example-file
	 * @secure
	 */
	bscoreSubListExcelImportExampleFileCreate = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		data: BscoreSubListExcelImportExampleFileCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<void, any>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/excel-import-example-file`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			...params,
		});
	/**
	 * @description 列表頁側邊欄，列出 Menu 和 MenuItem
	 *
	 * @tags bscore_menu
	 * @name BscoreSubMenuNaviList
	 * @request GET:/api/bscore/sub-menu-navi
	 * @secure
	 */
	bscoreSubMenuNaviList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: MenuResource[];
				};
			},
			any
		>({
			path: `/api/bscore/sub-menu-navi`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 列表頁側邊欄，沒有 Menu 的 Menuitem 會在此列出
	 *
	 * @tags bscore_menu
	 * @name BscoreMenuItemNaviList
	 * @request GET:/api/bscore/menu-item-navi
	 * @secure
	 */
	bscoreMenuItemNaviList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: MenuItemResource[];
				};
			},
			any
		>({
			path: `/api/bscore/menu-item-navi`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 用於列表頁側邊欄置底的 Menu 和 MenuItem
	 *
	 * @tags bscore_menu
	 * @name BscoreBottomMenuNaviList
	 * @request GET:/api/bscore/bottom-menu-navi
	 * @secure
	 */
	bscoreBottomMenuNaviList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						menus?: MenuResource[];
						menuItems?: MenuItemResource[];
					};
				};
			},
			any
		>({
			path: `/api/bscore/bottom-menu-navi`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_menu
	 * @name BscoreInfoDetail
	 * @summary 提供前端 menu_item_key 的資訊
	 * @request GET:/api/bscore/{menu_item_key}/info
	 * @secure
	 */
	bscoreInfoDetail = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/info`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 取得後台設定
	 *
	 * @tags bscore
	 * @name BscoreClientSettingsList
	 * @request GET:/api/bscore/client-settings
	 */
	bscoreClientSettingsList = (params: RequestParams = {}) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/client-settings`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_setting_sub_list
	 * @name BscoreSettingsEditDetail
	 * @summary 設定編輯頁顯示
	 * @request GET:/api/bscore/{menu_item_key}/settings/edit
	 * @secure
	 */
	bscoreSettingsEditDetail = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<BscoreImportResult, any>({
			path: `/api/bscore/${menuItemKey}/settings/edit`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_setting_sub_list
	 * @name BscoreSettingsUpdate
	 * @summary 設定編輯頁更新
	 * @request PUT:/api/bscore/{menu_item_key}/settings
	 * @secure
	 */
	bscoreSettingsUpdate = (
		menuItemKey: string,
		data: BscoreSettingsUpdateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<BscoreImportResult, any>({
			path: `/api/bscore/${menuItemKey}/settings`,
			method: 'PUT',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListSearchCreate
	 * @summary SubList 一般搜尋
	 * @request POST:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/search
	 * @secure
	 */
	bscoreSubListSearchCreate = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		data: SearchRequest,
		params: RequestParams = {},
	) =>
		this.http.request<GetListDataPaginator, any>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/search`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListCreateDetail
	 * @summary SubList 新增資料頁顯示
	 * @request GET:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/create
	 * @secure
	 */
	bscoreSubListCreateDetail = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/create`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListEditDetail
	 * @summary SubList 編輯資料頁顯示
	 * @request GET:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/{sub_id}/edit
	 * @secure
	 */
	bscoreSubListEditDetail = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		subId: string,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/${subId}/edit`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListCreate
	 * @summary SubList 新增
	 * @request POST:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}
	 * @secure
	 */
	bscoreSubListCreate = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						id?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}`,
			method: 'POST',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListUpdate
	 * @summary SubList 更新
	 * @request PUT:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/{sub_id}
	 * @secure
	 */
	bscoreSubListUpdate = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		subId: string,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						id?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/${subId}`,
			method: 'PUT',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListDelete
	 * @summary SubList 刪除
	 * @request DELETE:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/{sub_id}
	 * @secure
	 */
	bscoreSubListDelete = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		subId: string,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/${subId}`,
			method: 'DELETE',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListDestroyAllCreate
	 * @summary SubList 批次刪除
	 * @request POST:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/destroy-all
	 * @secure
	 */
	bscoreSubListDestroyAllCreate = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		data: BscoreSubListDestroyAllCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/destroy-all`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListUpdateRowCreate
	 * @summary SubList 列表單一行更新
	 * @request POST:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/update-row
	 * @secure
	 */
	bscoreSubListUpdateRowCreate = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		data: BscoreSubListUpdateRowCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/update-row`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListUpdateListCreate
	 * @summary SubList reorder/update status
	 * @request POST:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/update-list
	 * @secure
	 */
	bscoreSubListUpdateListCreate = (
		{ menuItemKey, id, subListKey, ...query }: BscoreSubListUpdateListCreateRequestParams,
		data: BscoreSubListUpdateListCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/update-list`,
			method: 'POST',
			query: query,
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreTaskDetail
	 * @summary 取得 task 狀態
	 * @request GET:/api/bscore/task/{task}
	 * @secure
	 */
	bscoreTaskDetail = (task: string, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						status?: 'WAITING' | 'PROCESSING' | 'FINISH' | 'FAILED';
						result?: BscoreExportResult | BscoreImportResult;
					};
				};
			},
			any
		>({
			path: `/api/bscore/task/${task}`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreUploadUploadUrlCreate
	 * @summary 取得檔案上傳的 url
	 * @request POST:/api/bscore/upload/upload-url
	 * @secure
	 */
	bscoreUploadUploadUrlCreate = (
		data: BscoreUploadUploadUrlCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						filename?: string;
						/** 上傳用 Url */
						uploadUrl?: string;
						url?: string;
					};
				};
			},
			any
		>({
			path: `/api/bscore/upload/upload-url`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description 取得後台版本資訊
	 *
	 * @tags bscore
	 * @name BscoreVersionList
	 * @request GET:/api/bscore/version
	 * @secure
	 */
	bscoreVersionList = (params: RequestParams = {}) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/version`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});

	/**
	 * No description
	 *
	 * @tags Dianthus-User
	 * @name V1UserImageReportList
	 * @summary 取得使眼科健檢列表
	 * @request GET:/api/v1/user/eye-report/list
	 * @secure
	 */
	v1EyeReportList = (query: EyeCheckImageListRequestParams, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						uid?: string;
						/** 檢查日期，格式yyyymmdd */
						date?: string;
						/** 醫師名稱 */
						dr?: string;
						/** 檢查說明 */
						description?: string;
						/** 靜態圖像部分路徑 */
						photo?: any[];
						/** 靜態影像部分路徑 */
						video?: any[];
					};
				};
			},
			any
		>({
			path: `/api/v1/user/eye-report/list/${query.patientNo}`,
			method: 'GET',
			// query: query,
			secure: true,
			format: 'json',
			...params,
		});

	/**
	 * No description
	 *
	 * @tags Dianthus-User
	 * @name V1UserImageReportList
	 * @summary 取得使眼科健檢列表
	 * @request GET:/api/v1/user/eye-report/track
	 * @secure
	 */
	v1EyeReportTrack = (query: any, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						correspondingValue? : any
						trackProjects? : any
					};
				};
			},
			any
		>({
			path: `/api/v1/user/eye-report/track/${query.patientNo}`,
			method: 'GET',
			// query: query,
			secure: true,
			format: 'json',
			...params,
		});

	/**
	 * No description
	 *
	 * @tags Dianthus-User
	 * @name v1CourseOptList
	 * @summary 取得禾馨課程預約列表
	 * @request GET:/api/v1/course/opt
	 * @secure
	 */
	v1CourseOptList = () =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						name?: string,
						sub_title?: string,
						info?: string,
					};
				};
			},
			any
		>({
			path: `/api/v1/course/opt`,
			method: 'GET',
			// query: query,
			secure: true,
			format: 'json',
			// ...params,
		});

	/**
	 * No description
	 *
	 * @tags Dianthus-User
	 * @name v1CourseOptTimeList
	 * @summary 根據預約courseTypeId取得禾馨本月預約列表
	 * @request GET:/api/v1/course/opt/{courseTypeId}
	 * @secure
	 */
	v1CourseOptTimeList = (query: any, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						id?: number,
						course_place_name?: string,
						name?: string,
						teacher?: string,
						startAt?: string,
						extra_url?: number,
						last_count?: number,
					}[];
				};
			},
			any
		>({
			path: `/api/v1/course/opt/${query.id}`,
			method: 'GET',
			// query: query,
			secure: true,
			format: 'json',
			...params,
		});

	/**
	 * No description
	 *
	 * @tags Dianthus-User
	 * @name v1CourseOptTimeList
	 * @summary 根據預約courseTypeId取得關於詳情內容
	 * @request GET:/api/v1/course/info/{courseId}
	 * @secure
	 */
	v1CourseOptCourseDetail = (query: any, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						id?: number,
						course_place_name?: string,
						name?: string,
						detail?: string,
						note?: string,
						fee?: number,
						teacher?: string,
						startAt?: string,
						extra_url?: number,
						last_count?: number,
						limit_up?: number,
					}[];
				};
			},
			any
		>({
			path: `/api/v1/course/info/${query.id}`,
			method: 'GET',
			// query: query,
			secure: true,
			format: 'json',
			...params,
		});

	/**
	 * No description
	 *
	 * @tags Dianthus-User
	 * @name v1CourseOptTimeList
	 * @summary 新增預約資料(訪客)
	 * @request POST:/api/v1/course/join/add
	 * @secure
	 */
	v1CourseJoinAdd = (data: any, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
					};
				};
			},
			any
		>({
			path: `/api/v1/course/join/add`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			secure: true,
			format: 'json',
			...params,
		});

	/**
	 * No description
	 *
	 * @tags Dianthus-User
	 * @name v1CourseCaptcha
	 * @summary 取得驗證碼
	 * @request GET:/api/v1/course/captcha
	 * @secure
	 */
	v1CourseCaptcha = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				img?: {
					
				},
				key?: {
					
				};
			},
			any
		>({
			path: `/api/v1/course/captcha`,
			method: 'GET',
			// query: query,
			secure: true,
			format: 'json',
			...params,
		});

	/**
	 * No description
	 *
	 * @tags Dianthus-User
	 * @name v1CourseCheck
	 * @summary 新增預約資料(訪客)
	 * @request POST:/api/v1/course/check
	 * @secure
	 */
	v1CourseCheck = (data: any, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data:any
				};
			},
			any
		>({
			path: `/api/v1/course/check`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			secure: true,
			format: 'json',
			...params,
		});

	/**
	 * No description
	 *
	 * @tags Dianthus-User
	 * @name v1CourseCaptcha
	 * @summary 使用身分證號取得預約列表(訪客)
	 * @request POST:/api/v1/course/booking
	 * @secure
	 */
	v1CourseBooking= (data: any, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data:any
				}
			},
			any
		>({
			path: `/api/v1/course/booking`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			secure: true,
			format: 'json',
			...params,
		});

	/**
	 * No description
	 *
	 * @tags Dianthus-User
	 * @name v1CourseCaptcha
	 * @summary 使用身分證號取得預約細節(訪客)
	 * @request POST:/api/v1/course/booking/{courseId}
	 * @secure
	 */
	v1CourseBookingDetail= (data: any, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data:any
				}
			},
			any
		>({
			path: `/api/v1/course/booking/${data.courseId}`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			secure: true,
			format: 'json',
			...params,
		});

	/**
	 * No description
	 *
	 * @tags Dianthus-User
	 * @name v1CourseCaptcha
	 * @summary 取消預約資料(訪客)
	 * @request POST:/api/v1/course/join/cancel
	 * @secure
	 */
	v1CourseJoinCancel= (data: any, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data:any
				},
				message: any
			},
			any
		>({
			path: `/api/v1/course/join/cancel`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			secure: true,
			format: 'json',
			...params,
		});

	/**
	 * No description
	 *
	 * @tags Dianthus-User
	 * @name v1CourseCaptcha
	 * @summary 取得地區
	 * @request POST:/api/v1/hospital/district
	 * @secure
	 */
	v1HospitalDistrict= (query: any, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data:any
				},
				message: any
			},
			any
		>({
			path: `/api/v1/hospital/district`,
			method: 'GET',
			// query: query,
			secure: true,
			format: 'json',
			...params,
		});

	/**
	 * No description
	 *
	 * @tags Dianthus-User
	 * @name v1CourseCaptcha
	 * @summary 根據地區取得醫院
	 * @request POST:/api/v1/hospital/district/{districtId}/branches
	 * @secure
	 */
	v1DistrictBranch= (query: any, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data:any
				},
				message: any
			},
			any
		>({
			path: `/api/v1/hospital/district/${query.id}/branches`,
			method: 'GET',
			// query: query,
			secure: true,
			format: 'json',
			...params,
		});

	/**
	 * No description
	 *
	 * @tags Dianthus-User
	 * @name v1CourseCaptcha
	 * @summary 根據地區取得醫院
	 * @request GET:/api/v1/hospital/branche/{branchId}/clinic-progress
	 * @secure
	 */
	v1MedicalProgress= (query: any, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data:any
				},
				message: any
			},
			any
		>({
			path: `/api/v1/hospital/branche/${query.id}/clinic-progress`,
			method: 'GET',
			// query: query,
			secure: true,
			format: 'json',
			...params,
		});

		/**
		 * No description
		 *
		 * @tags Dianthus-User
		 * @name v1CourseCaptcha
		 * @summary 取得小兒眼科篩檢報告
		 * @request GET:/api/v1/user/eye-report/page/{id}
		 * @secure
		 */
		v1EyeReportPage= (query: any, params: RequestParams = {}) =>
			this.http.request<
				ApiResponseTemplate & {
					data?: {
						data:any
					},
					message: any
				},
				any
			>({
				path: `/api/v1/user/eye-report/page/${query.patientNo}`,
				method: 'GET',
				// query: query,
				secure: true,
				format: 'json',
				...params,
			});

		/**
		 * No description
		 *
		 * @tags Dianthus-User
		 * @name v1CourseCaptcha
		 * @summary 取得小兒眼科篩檢報告
		 * @request GET:/api/v1/get-dept-form-url
		 * @secure
		 */
		v1GetDeptFormUrl= (query: any, params: RequestParams = {}) =>
			this.http.request<
				ApiResponseTemplate & {
					data?: {
						data:any
					},
				},
				any
			>({
				path: `/api/v1/get-dept-form-url`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			});


		/**
	 * No description
	 *
	 * @tags Dianthus-User
	 * @name v1CourseOptList
	 * @summary 取得禾馨課程預約列表(員工)
	 * @request GET:/api/v1/staff/course/opt
	 * @secure
	 */
	v1StaffCourseOptList = () =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						name?: string,
						sub_title?: string,
						info?: string,
					};
				};
			},
			any
		>({
			path: `/api/v1/staff/course/opt`,
			method: 'POST',
			// query: query,
			secure: true,
			format: 'json',
			// ...params,
		});

	/**
	 * No description
	 *
	 * @tags Dianthus-User
	 * @name v1CourseOptTimeList
	 * @summary 根據預約courseTypeId取得禾馨本月預約列表(員工)
	 * @request GET:/api/v1/staff/course/opt/{courseTypeId}
	 * @secure
	 */
	v1StaffCourseOptTimeList = (query: any, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						id?: number,
						course_place_name?: string,
						name?: string,
						teacher?: string,
						startAt?: string,
						extra_url?: number,
						last_count?: number,
					}[];
				};
			},
			any
		>({
			path: `/api/v1/staff/course/opt/${query.id}`,
			method: 'POST',
			// query: query,
			secure: true,
			format: 'json',
			...params,
		});

	/**
	 * No description
	 *
	 * @tags Dianthus-User
	 * @name v1CourseOptTimeList
	 * @summary 根據預約courseTypeId取得關於詳情內容(員工)
	 * @request GET:/api/v1/staff/course/info/{courseId}
	 * @secure
	 */
	v1StaffCourseOptCourseDetail = (query: any, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						id?: number,
						course_place_name?: string,
						name?: string,
						detail?: string,
						note?: string,
						fee?: number,
						teacher?: string,
						startAt?: string,
						extra_url?: number,
						last_count?: number,
						limit_up?: number,
					}[];
				};
			},
			any
		>({
			path: `/api/v1/staff/course/info/${query.id}`,
			method: 'POST',
			// query: query,
			secure: true,
			format: 'json',
			...params,
		});

	/**
	 * No description
	 *
	 * @tags Dianthus-User
	 * @name v1CourseOptTimeList
	 * @summary 新增預約資料(訪客)(員工)
	 * @request POST:/api/v1/staff/course/join/add
	 * @secure 
	 */
	v1StaffCourseJoinAdd = (data: any, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
					};
				};
			},
			any
		>({
			path: `/api/v1/staff/course/join/add`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			secure: true,
			format: 'json',
			...params,
		});

	/**
	 * No description
	 *
	 * @tags Dianthus-User
	 * @name v1CourseCaptcha
	 * @summary 使用身分證號取得預約列表(員工)
	 * @request POST:/api/v1staff//course/booking
	 * @secure
	 */
	v1StaffCourseBooking= (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data:any
				}
			},
			any
		>({
			path: `/api/v1/staff/course/booking`,
			method: 'POST',
			// body: data,
			type: ContentType.UrlEncoded,
			secure: true,
			format: 'json',
			...params,
		});

	/**
	 * No description
	 *
	 * @tags Dianthus-User
	 * @name v1CourseCaptcha
	 * @summary 使用身分證號取得預約細節(員工)
	 * @request POST:/api/v1/staff/course/booking/{courseId}
	 * @secure
	 */
	v1StaffCourseBookingDetail= (data: any, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data:any
				}
			},
			any
		>({
			path: `/api/v1/staff/course/booking/${data.courseId}`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			secure: true,
			format: 'json',
			...params,
		});

	/**
	 * No description
	 *
	 * @tags Dianthus-User
	 * @name v1CourseCaptcha
	 * @summary 取消預約資料(員工)
	 * @request POST:/api/v1/staff/course/join/cancel
	 * @secure
	 */
	v1StaffCourseJoinCancel= (data: any, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data:any
				},
				message: any
			},
			any
		>({
			path: `/api/v1/staff/course/join/cancel`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			secure: true,
			format: 'json',
			...params,
		});
	
	/**
	 * @description 取得醫師清單
	 *
	 * @tags Hospital
	 * @name V1HospitalDoctorList
	 * @summary 取得醫師清單
	 * @request GET:/api/v1/hospital/doctor-list
	 */
	v1HospitalDoctorList = (id:any, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: any[];
				};
			},
			any
		>({
			path: `/api/v1/hospital/doctor-list/${id}`,
			method: 'GET',
			format: 'json',
			...params,
		});
	
		/**
		 * @description 取得課程已報名列表(員工)
		 *
		 * @tags Hospital
		 * @name v1CourseJoinBookingList
		 * @summary 取得課程已報名列表(員工)
		 * @request GET:/api/v1/staff/course/join/booking-list
		 */
		v1CourseJoinBookingList = (params: RequestParams = {}) =>
			this.http.request<
				ApiResponseTemplate & {
					data?: {
						data?: any[];
					};
				},
				any
			>({
				path: `/api/v1/staff/course/join/booking-list`,
				method: 'GET',
				format: 'json',
				secure: true,
				...params,
			});
	
		/**
		 * @description 產生課程驗證碼
		 *
		 * @tags Hospital
		 * @name v1CourseJoinBookingList
		 * @summary 產生課程驗證碼
		 * @request GET:​/api​/v1​/course​/verify-code
		 */
		v1CourseVerifyCode = (data: any, params: RequestParams = {}) =>
			this.http.request<
				ApiResponseTemplate & {
					data?: {
						data?: {
							verifyCode: string,
							startTime: number
						};
					};
				},
				any
			>({
				path: `/api/v1/course/verify-code`,
				method: 'POST',
				body: data,
				type: ContentType.UrlEncoded,
				secure: true,
				format: 'json',
				...params,
			});
}

