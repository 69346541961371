import { createAction, handleActions, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { Dispatch } from 'redux';
import history from 'store/history';
import { nanoid } from 'nanoid';

import { api } from 'util/api';
import { HospitalizationListResource } from 'util/api/swaggerApi/data-contracts';
import { hospitalBranchMapping } from 'util/options';
import { FormControl } from 'util/formSystem/formControl';
import setupFormModel from 'util/formSystem/setupForm';

import { updateAccessToken } from './auth';

import { GetState, State as GlobalState } from './reducers';


interface DoctorType {
	id: string;
	value: string;
	optionName: string;
	relativeOptions: [];
}

export interface SelectorType {
	department: string;
	doctor: string;
}

export interface SelectorValueType {
	type: string;
	value: string;
}

type SetSelectorValueActionCreator = (type: 'doctor', value: string) => Action<SelectorValueType>;

type FilterActionCreator<T> = (department: string, doctor: string) => Action<T>;

export interface ExtendedHospitalizationListResource extends HospitalizationListResource {
	id: string;
	admissionReasonValue: string;
	isShowHeartRate: boolean;
	color?: string;
}

export interface State {
	loading: boolean;
	allInpatientList: ExtendedHospitalizationListResource[];
	tyInpatientList: ExtendedHospitalizationListResource[];
	mqInpatientList: ExtendedHospitalizationListResource[];
	xsInpatientList: ExtendedHospitalizationListResource[];
	yzInpatientList: ExtendedHospitalizationListResource[];
	slInpatientList: ExtendedHospitalizationListResource[];
	txInpatientList: ExtendedHospitalizationListResource[];
	currentListDetail: ExtendedHospitalizationListResource;
	doctorList: DoctorType[];
	currentFilteredList: ExtendedHospitalizationListResource[];
	selectedDepartment: FormControl<string>;
	selectedDoctor: FormControl<string>;
}

export const defaultState: State = {
	loading: false,
	allInpatientList: [],
	tyInpatientList: [],
	mqInpatientList: [],
	xsInpatientList: [],
	yzInpatientList: [],
	slInpatientList: [],
	txInpatientList: [],
	currentListDetail: {} as ExtendedHospitalizationListResource,
	doctorList: [],
	currentFilteredList: [],
	selectedDepartment: {
		value: 'all',
		errors: null,
	},
	selectedDoctor: {
		value: 'all',
		errors: null,
	},
};

export const formModelConfig = setupFormModel<State>('INPATIENT_FORM', (getState: GetState) => {
	const { inpatientList } = getState();
	return inpatientList;
});

export const setCurrentListDetail = createAction(
	'SET_CURRENT_LIST_DETAIL',
	(detail: ExtendedHospitalizationListResource) => detail,
);

const renewHospitalizationList = (data: HospitalizationListResource[]) => {
	const reasonMappings = [
		{ keyword: "待產", newValue: "待產中", isShowHeartRate: true },
		{ keyword: "安胎", newValue: "安胎中", isShowHeartRate: true },
		{ keyword: "自然產", newValue: "自然待產中", isShowHeartRate: true },
		{ keyword: "剖腹產", newValue: "剖腹待產中", isShowHeartRate: true },
		{ keyword: "休養", newValue: "休養中", isShowHeartRate: false },
	];

	return data.filter(item => item.name).map(item => {
		let newValue = item.admissionReason || '';
		let isShowHeartRate = false;

		// 如果還沒出生，才需要定義 admissionReasonValue
		if (!item.deliveryTime) {
			const mapping = reasonMappings.find(m => item.admissionReason && item.admissionReason.includes(m.keyword));
			if (mapping) {
				newValue = mapping.newValue;
				// 確定有 patientNo 才需要顯示心率
				isShowHeartRate = item.patientNo ? mapping.isShowHeartRate : false;
			}
		}

		return {
			...item,
			admissionReasonValue: newValue,
			isShowHeartRate,
			id: nanoid(),
		};
	});
};

export const getAllInpatientList = createAction(
	'GET_ALL_INPATIENT_LIST',
	() => async (dispatch: Dispatch) => {
		const { v1StaffHospitalizationListList } = api;
		try {
			const results = await Promise.allSettled([
				v1StaffHospitalizationListList({ hospital_branch: 'xs' }),
				v1StaffHospitalizationListList({ hospital_branch: 'mq' }),
				v1StaffHospitalizationListList({ hospital_branch: 'yz' }),
				v1StaffHospitalizationListList({ hospital_branch: 'sl' }),
				v1StaffHospitalizationListList({ hospital_branch: 'ty' }),
				v1StaffHospitalizationListList({ hospital_branch: 'tx' }),
			]);

			const allInpatientList: ExtendedHospitalizationListResource[] = [];
			const tyList: ExtendedHospitalizationListResource[] = [];
			const mqList: ExtendedHospitalizationListResource[] = [];
			const xsList: ExtendedHospitalizationListResource[] = [];
			const yzList: ExtendedHospitalizationListResource[] = [];
			const slList: ExtendedHospitalizationListResource[] = [];
			const txList: ExtendedHospitalizationListResource[] = [];

			results.forEach((result, index) => {
				if (result.status === 'fulfilled') {
					const { data } = result.value.data as unknown as { data: HospitalizationListResource[] };

					const hospitalizationList = renewHospitalizationList(data);

					switch (index) {
						case 0:
							xsList.push(...hospitalizationList);
							break;
						case 1:
							mqList.push(...hospitalizationList);
							break;
						case 2:
							yzList.push(...hospitalizationList);
							break;
						case 3:
							slList.push(...hospitalizationList);
							break;
						case 4:
							tyList.push(...hospitalizationList);
							break;
						case 5:
							txList.push(...hospitalizationList);
							break;
						default:
							break;
					}
					allInpatientList.push(...hospitalizationList);
				} else if (result.status === 'rejected') {
					console.log('inpatient list request failed:', result.reason);
					const { status } = result.reason as { status: number };
					if (status === 401) {
						dispatch(updateAccessToken(''));
						history.push('/');
					}
				}
			});

			dispatch({ type: 'SET_TY_INPATIENT_LIST', payload: tyList });
			dispatch({ type: 'SET_MQ_INPATIENT_LIST', payload: mqList });
			dispatch({ type: 'SET_XS_INPATIENT_LIST', payload: xsList });
			dispatch({ type: 'SET_YZ_INPATIENT_LIST', payload: yzList });
			dispatch({ type: 'SET_SL_INPATIENT_LIST', payload: slList });
			dispatch({ type: 'SET_TX_INPATIENT_LIST', payload: txList });

			return allInpatientList;
		} catch (e) {
			console.log('inpatient list error', e);
			const { status } = e as { status: number };
			if (status === 401) {
				dispatch(updateAccessToken(''));
				history.push('/');
			}
			return [];
		}
	},
);

export const getDoctorList = createAction('GET_DOCTOR_LIST', () => async (dispatch: Dispatch, getState: GetState) => {
	const { v1StaffDoctorListList } = api;
	const { user: { userInfo, staffPermissionInfo } } = getState();
	const firstOption = {
		id: nanoid(),
		value: 'all',
		optionName: '所有醫師',
		relativeOptions: [],
	};
	const secondOption = {
		id: nanoid(),
		value: userInfo.name,
		optionName: userInfo.name,
		relativeOptions: [],
	};

	try {
		const { data } = await v1StaffDoctorListList();
		let formattedData:any = [];
		if (staffPermissionInfo.isDoctor) {
			formattedData = [
				firstOption,
				secondOption,
				...(data?.data?.map(doctor => ({
					id: nanoid(),
					value: doctor.name,
					optionName: doctor.name,
					relativeOptions: [],
				})) || []).filter(item => item.optionName !== staffPermissionInfo.defaultSelectorDoctorValue)
			];
		} else {
			formattedData = [
				firstOption,
				...(data?.data?.map(doctor => ({
					id: nanoid(),
					value: doctor.name,
					optionName: doctor.name,
					relativeOptions: [],
				})) || [])
			];
		}

		return formattedData;
	} catch (e) {
		console.log('doctor list error', e);
		const { status } = e as { status: number };
		if (status === 401) {
			dispatch(updateAccessToken(''));
			history.push('/');
		}
		return [];
	}
});

export const filterInpatientList = createAction(
	'FILTER_INPATIENT_LIST',
	() => (_: Dispatch, getState: GetState) => {
		const { inpatientList } = getState();
		const department = inpatientList.selectedDepartment.value;
		const doctor = inpatientList.selectedDoctor.value;

		if (department === 'all' && doctor === 'all') {
			return inpatientList.allInpatientList;
		}
		if (department === '' && doctor === '') {
			return [];
		}

		let filteredList = inpatientList.allInpatientList;
		let list = inpatientList.allInpatientList;

		if (department !== 'all') {
			filteredList = inpatientList[`${department}InpatientList` as 'tyInpatientList'];
		}

		if (doctor !== 'all') {
			filteredList = filteredList.filter(item => item.outpatientPhysician
				=== doctor || item.midwife === doctor);
				// if (filteredList.length === 0) {
				// 	filteredList = list;
				// }
		} else {
			// filteredList = filteredList.filter(item => item.outpatientPhysician
			// 	=== doctor || item.midwife === doctor);
		}
		return filteredList;

	},
);

export const initInpatientListAndDoctorValue = createAction(
	'INIT_VALUE', () => async (dispatch: Dispatch, getState: GetState) => {
		const {
			user: { staffPermissionInfo },
		} = getState();

		if (staffPermissionInfo.isDoctor) {
			dispatch(formModelConfig.actions.setFormCtrlValue('selectedDoctor', staffPermissionInfo.defaultSelectorDoctorValue));
		}

		dispatch(filterInpatientList());
	}
);

export const initListAndDoctorValue = createAction(
	'INIT_LIST_AND_DOCTOR_VALUE', <T>(setSelectorValueAction: SetSelectorValueActionCreator, filterAction: FilterActionCreator<T>) => (dispatch: Dispatch, getState: GetState) => {
		const {
			user: { staffPermissionInfo },
		} = getState();

		dispatch(setSelectorValueAction('doctor', staffPermissionInfo.defaultSelectorDoctorValue));

		if (staffPermissionInfo.isDoctor) {
			dispatch(filterAction('all', staffPermissionInfo.defaultSelectorDoctorValue));
		} else if (!staffPermissionInfo.isDoctor && !staffPermissionInfo.hasDropdownPermission) {
			dispatch(filterAction('', ''));
		} else {
			dispatch(filterAction('all', 'all'));
		}
	}
);

export const cleanInpatientSelectorValue = createAction(
	'CLEAN_INPATIENT_SELECTOR_VALUE')

export const seeHeartRateAssessment = createAction(
	'SEE_HEART_RATE_ASSESSMENT',
	() => async (_: Dispatch, getState: GetState) => {
		const {
			inpatientList: { currentListDetail },
			auth: { isWebview },
		} = getState();

		if (currentListDetail.hospitalBranch) {
			const url = `https://ob-${
				hospitalBranchMapping[currentListDetail.hospitalBranch]
			}.dianthus.com.tw/obweb/singleBedQuery.aspx?patientNo=${currentListDetail.patientNo}`;

			// 如果是在 webview 內，就不另開新視窗
			if (isWebview) {
				window.location.href = url;
			} else {
				window.open(url, '_blank');
			}
		}
	},
);

export const reducer = {
	// Workaround: HandleActions 目前定義無法支援多種 action 形式
	inpatientList: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			...formModelConfig.reducers,
			GET_INPATIENT_LIST_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_ALL_INPATIENT_LIST_FULFILLED: (
				state,
				action: Action<ExtendedHospitalizationListResource[]>,
			) => ({
				...state,
				allInpatientList: [...action.payload],
				loading: false,
			}),
			SET_TY_INPATIENT_LIST: (state, action: Action<ExtendedHospitalizationListResource[]>) => ({
				...state,
				tyInpatientList: [...action.payload],
				loading: false,
			}),
			SET_MQ_INPATIENT_LIST: (state, action: Action<ExtendedHospitalizationListResource[]>) => ({
				...state,
				mqInpatientList: [...action.payload],
				loading: false,
			}),
			SET_XS_INPATIENT_LIST: (state, action: Action<ExtendedHospitalizationListResource[]>) => ({
				...state,
				xsInpatientList: [...action.payload],
				loading: false,
			}),
			SET_YZ_INPATIENT_LIST: (state, action: Action<ExtendedHospitalizationListResource[]>) => ({
				...state,
				yzInpatientList: [...action.payload],
				loading: false,
			}),
			SET_SL_INPATIENT_LIST: (state, action: Action<ExtendedHospitalizationListResource[]>) => ({
				...state,
				slInpatientList: [...action.payload],
				loading: false,
			}),
			SET_TX_INPATIENT_LIST: (state, action: Action<ExtendedHospitalizationListResource[]>) => ({
				...state,
				txInpatientList: [...action.payload],
				loading: false,
			}),
			SET_CURRENT_LIST_DETAIL: (state, action: Action<ExtendedHospitalizationListResource>) => ({
				...state,
				currentListDetail: action.payload,
				loading: false,
			}),
			GET_DOCTOR_LIST_FULFILLED: (state, action: Action<DoctorType[]>) => ({
				...state,
				doctorList: [...action.payload],
				loading: false,
			}),
			FILTER_INPATIENT_LIST: (state, action: Action<ExtendedHospitalizationListResource[]>) => ({
				...state,
				currentFilteredList: [...action.payload],
				loading: false,
			}),
			CLEAN_INPATIENT_SELECTOR_VALUE: state => ({
				...state,
				selectedDepartment: {
					value: 'all',
					errors: null,
				},
				selectedDoctor: {
					value: 'all',
					errors: null,
				},
			}),
		},
		defaultState,
	),
};

const inpatientListActionsMap = {
	...formModelConfig.actions,
	getAllInpatientList,
	setCurrentListDetail,
	getDoctorList,
	filterInpatientList,
	seeHeartRateAssessment,
	initListAndDoctorValue,
	cleanInpatientSelectorValue,
};

const mapHooksToState = (state: GlobalState) => ({
	allInpatientList: state.inpatientList.allInpatientList,
	ty: state.inpatientList.tyInpatientList,
	mq: state.inpatientList.mqInpatientList,
	xs: state.inpatientList.xsInpatientList,
	yz: state.inpatientList.yzInpatientList,
	sl: state.inpatientList.slInpatientList,
	tx: state.inpatientList.txInpatientList,
	currentListDetail: state.inpatientList.currentListDetail,
	currentFilteredList: state.inpatientList.currentFilteredList,
	doctorList: state.inpatientList.doctorList,
	selectedDepartment: state.inpatientList.selectedDepartment,
	selectedDoctor: state.inpatientList.selectedDoctor,
});

type InpatientListSelector = ReturnType<typeof mapHooksToState>;
type InpatientListActionsMap = typeof inpatientListActionsMap;

export const useInpatientList = () =>
	useRedux<InpatientListSelector, InpatientListActionsMap>(
		mapHooksToState,
		inpatientListActionsMap,
	);
