import { createAction, handleActions, Action } from 'redux-actions';
import { Dispatch } from 'redux';
import { nanoid } from 'nanoid';
import { md5 } from 'js-md5';

import { useRedux } from 'util/hook/redux';
import { StaffClinicDeptResource, StaffResource } from 'util/api/swaggerApi/data-contracts';

import ImageIcon from 'images/icon/image.inline.svg';
import UserIcon from 'images/icon/user.inline.svg';
import ReportIcon from 'images/icon/report.inline.svg';
import MemberIcon from 'images/icon/member.inline.svg';
import ExternalLinkIcon from 'images/icon/external-link.inline.svg';
import EyeIcon from 'images/icon/eye.inline.svg';

import { GetState, State as GlobalState } from './reducers';

export interface RouteMenu {
	id: string;
	title: string;
	icon: string;
	iconPosition?: 'left' | 'right';
	to: string;
	isShowOnNav: boolean;
	allowPermission: boolean;
	isExternalLink?: boolean;
	subMenus: RouteMenu[];
}

export interface RouteMenuStatus {
	id: string;
	isOpen: boolean;
}

export const ROUTE_PATHS = {
	IMAGE_REPORT: 'image-report',
	HEALTH_REPORT: 'health-report',
	USER_INFO: 'user-info',
	BEAUTY_CLINIC: 'beauty-clinic',
	STAFF: 'staff',
	INPATIENT_LIST: 'inpatient-list',
	SURGERY_LIST: 'surgery-list',
	NEWBORN_LIST: 'newborn-list',
	SIGN_UP: 'sign-up',
	BASIC_INFO: 'basic-info',
	GET_PASSWORD: 'get-password',
	FORGOT_PASSWORD: 'forgot-password',
	IDENTITY_VERIFICATION: 'identity-verification',
	// 外部連結
	EIP: 'eip.dianthus.com.tw/AppIndex.aspx?',
	BPM: 'bpm.dianthus.com.tw/FlowBPM/App_Login.aspx?',
	APPOINTMENTS: 'appointments',
	REGISTRATIONS: 'registrations',
	REGISTRATION_START: 'start',
	REGISTRATION_FAILURE: 'failure',
	WELCOME: 'welcome',
	REGISTRATIONS_LIST: 'records',
	EYE_REPORT: 'eye-report',
	EYE_CHECK_IMAGE: 'eye-check-image',
	NEARSIGHTED_TRACK_MEDICINE: 'nearsighted-track-medicine',
	NEARSIGHTED_TRACK: 'nearsighted-track-angle',
	GLAUCOMA_TRACK: 'glaucoma-track',
	OPTOMERY_REPORT: 'optometry-report',
	WEAKNESS_TRAIN_TRACK: 'weakness-train-track',
	COURSE_RESERVATION: 'course-reservation',
	COURSE_RESERVATION_REGISTRATIONS: 'registrations',
	COURSE_RESERVATION_START: 'start',
	COURSE_RESERVATION_SEARCH: 'search',
	COURSE_RESERVATION_CANCEL: 'cancel',
	COURSE_RESERVATION_LIST: 'records',
	CHECK_IN_ITEM: 'check-in-item',
	DIAGNOSIS_INFO: 'diagnosis-info',
};

// default 為非員工的選單
const ROUTE_MENUS: RouteMenu[] = [
	{
		id: nanoid(),
		title: '影像報告查詢',
		icon: ImageIcon,
		iconPosition: 'left',
		to: `/${ROUTE_PATHS.IMAGE_REPORT}`,
		isShowOnNav: true,
		allowPermission: true,
		subMenus: [],
		isExternalLink: false,
	},
	{
		id: nanoid(),
		title: '檢驗報告查詢',
		icon: ReportIcon,
		iconPosition: 'left',
		to: `/${ROUTE_PATHS.HEALTH_REPORT}`,
		isShowOnNav: true,
		allowPermission: true,
		subMenus: [],
		isExternalLink: false,
	},
	{
		id: nanoid(),
		title: '眼科報告查詢',
		icon: EyeIcon,
		iconPosition: 'left',
		to: `/${ROUTE_PATHS.EYE_REPORT}`,
		isShowOnNav: true,
		allowPermission: true,
		isExternalLink: false,
		subMenus: [
			{
				id: nanoid(),
				icon: '',
				title: '弱視訓練追蹤',
				to: `/${ROUTE_PATHS.WEAKNESS_TRAIN_TRACK}`,
				isShowOnNav: true,
				allowPermission: true,
				isExternalLink: false,
				subMenus: []
			},
			{
				id: nanoid(),
				icon: '',
				title: '近視追蹤控制(藥物)',
				to: `/${ROUTE_PATHS.NEARSIGHTED_TRACK_MEDICINE}`,
				isShowOnNav: true,
				allowPermission: true,
				isExternalLink: false,
				subMenus: []
			},
			{
				id: nanoid(),
				icon: '',
				title: '近視追蹤控制(角塑)',
				to: `/${ROUTE_PATHS.NEARSIGHTED_TRACK}`,
				isShowOnNav: true,
				allowPermission: true,
				isExternalLink: false,
				subMenus: []
			},
			{
				id: nanoid(),
				icon: '',
				title: '青光眼追蹤',
				to: `/${ROUTE_PATHS.GLAUCOMA_TRACK}`,
				isShowOnNav: true,
				allowPermission: true,
				isExternalLink: false,
				subMenus: []
			},
			{
				id: nanoid(),
				icon: '',
				title: '小兒驗光眼位篩檢報告',
				to: `/${ROUTE_PATHS.OPTOMERY_REPORT}`,
				isShowOnNav: true,
				allowPermission: true,
				isExternalLink: false,
				subMenus: []
			},
			{
				id: nanoid(),
				icon: '',
				title: '眼科檢查影像',
				to: `/${ROUTE_PATHS.EYE_CHECK_IMAGE}`,
				isShowOnNav: true,
				allowPermission: true,
				isExternalLink: false,
				subMenus: []
			}
		]
	},
	{
		id: nanoid(),
		title: '會員資料管理',
		icon: UserIcon,
		iconPosition: 'left',
		to: `/${ROUTE_PATHS.USER_INFO}`,
		isShowOnNav: true,
		allowPermission: true,
		subMenus: [],
		isExternalLink: false,
	},
	{
		id: nanoid(),
		title: '預約及掛號',
		icon: '',
		to: `/${ROUTE_PATHS.APPOINTMENTS}`,
		isShowOnNav: false,
		allowPermission: true,
		subMenus: [{
			id: nanoid(),
			icon: '',
			title: '網路掛號與查詢',
			to: `/${ROUTE_PATHS.REGISTRATIONS}`,
			isShowOnNav: false,
			allowPermission: true,
			subMenus: [
				{
					id: nanoid(),
					icon: '',
					title: '網路掛號',
					to: `/${ROUTE_PATHS.REGISTRATION_START}`,
					isShowOnNav: false,
					allowPermission: true,
					subMenus: [],
				},
				{
					id: nanoid(),
					icon: '',
					title: '掛號查詢',
					to: `/${ROUTE_PATHS.REGISTRATIONS_LIST}`,
					isShowOnNav: false,
					allowPermission: true,
					subMenus: [
						{
							id: nanoid(),
							icon: '',
							title: '掛號資料',
							to: '/:id',
							isShowOnNav: false,
							allowPermission: false,
							subMenus: [],
						},
					],
				},
			],
		},],
	},
	{
		id: nanoid(),
		title: '課程活動及預約',
		icon: '',
		to: `/${ROUTE_PATHS.COURSE_RESERVATION}`,
		isShowOnNav: false,
		allowPermission: true,
		subMenus: [{
			id: nanoid(),
			icon: '',
			title: '課程活動與查詢',
			to: `/${ROUTE_PATHS.COURSE_RESERVATION_REGISTRATIONS}`,
			isShowOnNav: false,
			allowPermission: true,
			subMenus: [
				{
					id: nanoid(),
					icon: '',
					title: '課程活動',
					to: `/${ROUTE_PATHS.COURSE_RESERVATION_START}`,
					isShowOnNav: false,
					allowPermission: true,
					subMenus: [],
				},
				{
					id: nanoid(),
					icon: '',
					title: '預約查詢',
					to: `/${ROUTE_PATHS.COURSE_RESERVATION_SEARCH}`,
					isShowOnNav: false,
					allowPermission: true,
					subMenus: [],
				},
				{
					id: nanoid(),
					icon: '',
					title: '預約列表',
					to: `/${ROUTE_PATHS.COURSE_RESERVATION_LIST}`,
					isShowOnNav: false,
					allowPermission: true,
					subMenus: [
						{
							id: nanoid(),
							icon: '',
							title: '預約內頁',
							to: '/:id',
							isShowOnNav: false,
							allowPermission: false,
							subMenus: [],
						},
					],
				},
				{
					id: nanoid(),
					icon: '',
					title: '預約查詢',
					to: `/${ROUTE_PATHS.COURSE_RESERVATION_CANCEL}`,
					isShowOnNav: false,
					allowPermission: true,
					subMenus: [],
				},
			],
		},],
	},
	{
		id: nanoid(),
		icon: MemberIcon,
		iconPosition: 'left',
		title: '員工專區',
		to: `/${ROUTE_PATHS.STAFF}`,
		isShowOnNav: true,
		allowPermission: false,
		isExternalLink: false,
		subMenus: [
			{
				id: nanoid(),
				icon: '',
				title: '住院清單',
				to: `/${ROUTE_PATHS.INPATIENT_LIST}`,
				isShowOnNav: true,
				allowPermission: false,
				isExternalLink: false,
				subMenus: [
					{
						id: nanoid(),
						icon: '',
						title: '住院資料',
						to: '/:id',
						isShowOnNav: false,
						allowPermission: false,
						isExternalLink: false,
						subMenus: [],
					},
				],
			},
			{
				id: nanoid(),
				icon: '',
				title: '開刀房清單',
				to: `/${ROUTE_PATHS.SURGERY_LIST}`,
				isShowOnNav: true,
				allowPermission: false,
				isExternalLink: false,
				subMenus: [
					{
						id: nanoid(),
						icon: '',
						title: '開刀資料',
						to: '/:id',
						isShowOnNav: false,
						allowPermission: false,
						isExternalLink: false,
						subMenus: [],
					},
				],
			},
			{
				id: nanoid(),
				icon: '',
				title: '新生兒住院清單',
				to: `/${ROUTE_PATHS.NEWBORN_LIST}`,
				isShowOnNav: true,
				allowPermission: false,
				isExternalLink: false,
				subMenus: [
					{
						id: nanoid(),
						icon: '',
						title: '新生兒住院資料',
						to: '/:id',
						isShowOnNav: false,
						allowPermission: false,
						isExternalLink: false,
						subMenus: [],
					},
				],
			},
			{
				id: nanoid(),
				title: '醫美和特約門診',
				icon: '',
				to: `/${ROUTE_PATHS.BEAUTY_CLINIC}`,
				isShowOnNav: true,
				allowPermission: false,
				isExternalLink: false,
				subMenus: [
					{
						id: nanoid(),
						icon: '',
						title: '門診資料',
						to: '/:id',
						isShowOnNav: false,
						allowPermission: false,
						isExternalLink: false,
						subMenus: [],
					},
				],
			},
			{
				id: nanoid(),
				title: 'EIP 系統',
				icon: ExternalLinkIcon,
				iconPosition: 'right',
				to: `/${ROUTE_PATHS.EIP}`,
				isShowOnNav: true,
				allowPermission: true,
				isExternalLink: true,
				subMenus: [],
			},
			{
				id: nanoid(),
				title: '電子表單簽核',
				icon: ExternalLinkIcon,
				iconPosition: 'right',
				to: `/${ROUTE_PATHS.BPM}`,
				isShowOnNav: true,
				allowPermission: true,
				isExternalLink: true,
				subMenus: [],
			},
		],
	},
];

export interface State {
	loading: boolean;
	menus: RouteMenuStatus[];
	routeMenus: RouteMenu[];
}

export const defaultState: State = {
	loading: false,
	menus: [],
	routeMenus: ROUTE_MENUS,
};

export const initMenusOpenStatus = createAction('INIT_MENUS_OPEN_STATUS', () => {
	const newMenus: RouteMenuStatus[] = [];

	// 初始化選單的收合狀態
	const appendToMenus = (list: RouteMenu[]) => {
		list.forEach(routeMenu => {
			const isOpen = routeMenu.to === `/${ROUTE_PATHS.STAFF}` || routeMenu.to === `/${ROUTE_PATHS.EYE_REPORT}`;
			newMenus.push({
				id: routeMenu.id,
				isOpen,
			});
			appendToMenus(routeMenu.subMenus);
		});
	};

	appendToMenus(ROUTE_MENUS);

	return newMenus;
});

export const toggleMenu = createAction(
	'TOGGLE_MENU',
	(id: string) => (_: Dispatch, getState: GetState) => {
		const {
			sideMenu: { menus },
		} = getState();

		const targetIdx = menus.findIndex(menu => menu.id === id);
		const newMenu = {
			...menus[targetIdx],
			isOpen: !menus[targetIdx].isOpen,
		};
		const preList = menus.slice(0, targetIdx);
		const nextList = menus.slice(targetIdx + 1);

		return [...preList, newMenu, ...nextList];
	},
);

export const updateRouteMenus = createAction(
	'UPDATE_ROUTE_MENUS',
	() => (_: Dispatch, getState: GetState) => {
		const {
			sideMenu: { routeMenus },
			user: { staffPermissionInfo, userInfo },
		} = getState();

		// 測試用要改要刪除
		// const permissions = [
		// 	'hospitalization-list',
		// 	'operating-room-appointment-list',
		// 	'newborn-hospitalization-list',
		// 	'aesthetic-medicine-appointment-list',
		// ];
		const permissions = staffPermissionInfo.permissions || [];
		const updatedMenus = routeMenus.map((menu: RouteMenu) => {
			if (menu.to === `/${ROUTE_PATHS.STAFF}` && userInfo.isStaff) {
				// 雖然是員工，但是沒有任何權限，則不顯示員工專區
				if (staffPermissionInfo.permissions?.length === 0) return menu;

				const routePermissionMap = {
					[`/${ROUTE_PATHS.INPATIENT_LIST}`]: 'hospitalization-list',
					[`/${ROUTE_PATHS.SURGERY_LIST}`]: 'operating-room-appointment-list',
					[`/${ROUTE_PATHS.NEWBORN_LIST}`]: 'newborn-hospitalization-list',
					[`/${ROUTE_PATHS.BEAUTY_CLINIC}`]: 'aesthetic-medicine-appointment-list',
				};

				// 根據權限設置員工專區及其子菜单的 allowPermission
				const menuCopy = { ...menu, allowPermission: true };
				menuCopy.subMenus = menu.subMenus.map(subMenu => {
					// 處理 ’EIP‘ 的 menu 連結
					if (subMenu.title === 'EIP 系統') {
						const { staffInfo } = userInfo;
						const { clinicDept } = staffInfo as StaffResource;
						const data = clinicDept !== undefined ? (clinicDept[0] as StaffClinicDeptResource) : {};
						const { eid } = data as StaffClinicDeptResource;

						const query = `ACID=${eid}`;

						return { ...subMenu, to: `${subMenu.to}${query}` };
					}

					// 處理 ‘電子表單簽核’ 的 menu 連結
					if (subMenu.title === '電子表單簽核') {
						const { twid } = userInfo;

						const date = new Date();
						const formattedDate = date.toISOString().split('T')[0].replace(/-/g, '');

						const token = `DianThus-${twid}-Bpm-${formattedDate}`;
						const MD5_TOKEN = md5(token);

						const query = `twid=${twid}&token=${MD5_TOKEN}`;

						return { ...subMenu, to: `${subMenu.to}${query}` };
					}

					const allowPermission = permissions.includes(routePermissionMap[subMenu.to]);
					return { ...subMenu, allowPermission };
				});

				return menuCopy;
			}
			return menu;
		});
		return updatedMenus;
	},
);

export const resetRouteMenus = createAction('RESET_ROUTE_MENUS', () => ROUTE_MENUS);

export const reducer = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	sideMenu: handleActions<State, any>(
		{
			INIT_MENUS_OPEN_STATUS: (state, action: Action<RouteMenuStatus[]>) => ({
				...state,
				menus: action.payload,
			}),
			TOGGLE_MENU: (state, action: Action<RouteMenuStatus[]>) => ({
				...state,
				menus: action.payload,
			}),
			UPDATE_ROUTE_MENUS: (state, action: Action<RouteMenu[]>) => ({
				...state,
				routeMenus: action.payload,
			}),
			RESET_ROUTE_MENUS: (state, action: Action<RouteMenu[]>) => ({
				...state,
				routeMenus: action.payload,
			}),
		},
		defaultState,
	), // eslint-disable-line @typescript-eslint/no-explicit-any
};

const sideMenuActionsMap = {
	toggleMenu,
	updateRouteMenus,
	resetRouteMenus,
};

const mapHooksToState = (state: GlobalState) => ({
	menus: state.sideMenu.menus,
	routeMenus: state.sideMenu.routeMenus,
});

type SideMenuSelector = ReturnType<typeof mapHooksToState>;
type SideMenuActionsMap = typeof sideMenuActionsMap;

export const useSideMenu = () =>
	useRedux<SideMenuSelector, SideMenuActionsMap>(mapHooksToState, sideMenuActionsMap);
