/* eslint-disable no-restricted-syntax */
import { createAction, handleActions, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { AnyAction, Dispatch, Store } from 'redux';
import history from 'store/history';
import { nanoid } from 'nanoid';

import { ROUTE_PATHS, RouteMenu, resetRouteMenus } from 'models/sideMenu';

import { ApiError, api } from 'util/api';
import {
	STORAGE_KEY,
	StorageType,
	getItemFromStorage,
	removeItemInStorage,
	setItemToStorage,
} from 'util/hook/useStorage';
import { V1AuthLoginCreateRequestPayload } from 'util/api/swaggerApi/data-contracts';

import { formModelConfig as loginFormConfig, clearLoginForm } from './loginForm';
import { ToastStatus, openToast } from './toast';

import { GetState, State as GlobalState } from './reducers';
import { getUserPermission } from './user';
import { isAppointmentPage, advanceToNextStep } from 'util/routeHelper';

export interface State {
	loading: boolean;
	token: string;
	isWebview: boolean;
}

export const defaultState: State = {
	loading: false,
	token: '',
	isWebview: false,
};

const findRouteByPathName = (routeMenus: RouteMenu[], pathName: string): RouteMenu | null => {
	for (const menu of routeMenus) {
		if (menu.to === pathName) {
			return menu;
		}
		if (menu.subMenus.length > 0) {
			const subMenu = findRouteByPathName(menu.subMenus, pathName);
			if (subMenu) {
				return subMenu;
			}
		}
	}
	return null;
};

export const checkAuthGuard = (store: Store<GlobalState, AnyAction>, pathName: string): boolean => {
	const { auth } = store.getState();
	const {
		sideMenu: { routeMenus },
	} = store.getState();

	if (!auth.token) {
		history.push('/');
	}

	const menu = findRouteByPathName(routeMenus, pathName);
	const pageAuth = menu ? menu.allowPermission : false;

	if (!pageAuth) {
		history.push(`/${ROUTE_PATHS.IMAGE_REPORT}`);
	}

	return !!auth.token && pageAuth;
};

export const login = createAction(
	'LOGIN',
	(params: V1AuthLoginCreateRequestPayload) =>
		async (dispatch: Dispatch, getState: GetState): Promise<null> => {
			const { v1AuthLoginCreate } = api;

			try {
				const { data, status } = await v1AuthLoginCreate(params);
				const token: string = data?.data?.token || '';

				if ((status === 200 || status === 201) && token) {
					await dispatch(updateAccessToken(token));
					await dispatch(getUserPermission());
					await dispatch(clearLoginForm());

					// 為了抓 user 最新的資料
					const {
						user: { firstDiagnosisInfo },
					} = getState();

					// 如果當前頁面在預約掛號
					if (isAppointmentPage(window.location.pathname)) {
						advanceToNextStep();
					}
					else if (firstDiagnosisInfo.firstDiagnosis) {
						history.push(ROUTE_PATHS.WELCOME);
					} else {
						history.push(ROUTE_PATHS.IMAGE_REPORT);
					}

					return null;
				}
			} catch (error) {
				// const apiError = error.error as ApiError;
				const apiError = (error as ApiError).error;
				const errorMessage = apiError?.message || '';
				if (errorMessage === '帳號或密碼錯誤') {
					dispatch(loginFormConfig.actions.setFormCtrlErrors('password', { loginFailed: true }));
					dispatch(loginFormConfig.actions.setFormCtrlErrors('birthday', null));
				} else if (errorMessage === '查無此會員') {
					dispatch(loginFormConfig.actions.setFormCtrlErrors('twid', { userNotFound: true }));
				} else {
					throw new Error(errorMessage);
				}
			}
			return null;
		},
);

export const updateAccessToken = createAction('UPDATE_ACCESS_TOKEN', (newToken: string) => {
	setItemToStorage(STORAGE_KEY.TOKEN, newToken, StorageType.LOCAL);
	return newToken;
});

export const setIsWebview = createAction('SET_IS_WEBVIEW', (isWebview: boolean) => isWebview);

export const loadTokenFromLocalstorage = createAction('LOAD_TOKEN_FROM_LOCALSTORAGE', () => {
	const token = getItemFromStorage<string>(STORAGE_KEY.TOKEN, StorageType.LOCAL);
	return token || '';
});

export const logout = createAction('LOGOUT', () => async (dispatch: Dispatch) => {
	const { v1AuthLogoutCreate } = api;

	try {
		const { status, message } = await v1AuthLogoutCreate();

		if (status !== 200 && status !== 201) {
			throw new Error(typeof message === 'string' ? message : 'An unknown error occurred');
		}
		removeItemInStorage(STORAGE_KEY.TOKEN, StorageType.LOCAL);
		dispatch(updateAccessToken(''));
		dispatch(resetRouteMenus());
		history.push('/');
		dispatch(openToast({ toastId: nanoid(), status: ToastStatus.SUCCESS, text: '您已登出' }));
		return null;
	} catch (error) {
		console.log('logout error', error);
		dispatch(updateAccessToken(''));
		history.push('/');
		return null;
	}
});

export const reducer = {
	auth: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			LOGIN_PENDING: state => ({
				...state,
				loading: true,
			}),

			LOGIN_FULFILLED: state => ({
				...state,
				loading: false,
			}),

			LOAD_TOKEN_FROM_LOCALSTORAGE: (state, action: Action<string>) => ({
				...state,
				token: action.payload,
			}),

			LOGOUT: state => ({
				...state,
				token: '',
			}),

			UPDATE_ACCESS_TOKEN: (state, action: Action<string>) => ({
				...state,
				token: action.payload,
			}),
			SET_IS_WEBVIEW: (state, action: Action<boolean>) => ({
				...state,
				isWebview: action.payload,
			}),
		},
		defaultState,
	),
};

const authActionsMap = {
	login,
	logout,
	setIsWebview,
};

const mapHooksToState = (state: GlobalState) => ({
	loading: state.auth.loading,
	auth: state.auth,
	isWebview: state.auth.isWebview,
});

type AuthSelector = ReturnType<typeof mapHooksToState>;
type AuthActionsMap = typeof authActionsMap;

export const useAuth = () =>
	useRedux<AuthSelector, AuthActionsMap>(mapHooksToState, authActionsMap);
