import React from 'react';
import history from 'store/history';
import { nanoid } from 'nanoid';

import { loadTokenFromLocalstorage, setIsWebview } from 'models/auth';
import { getUserPermission } from 'models/user';
import { ROUTE_PATHS } from 'models/sideMenu';
import { ToastStatus, openToast } from 'models/toast';

import { getRouteQuery, isNeedTokenPage, isNotNeedTokenPage } from 'util/routeHelper';
import { CustomRoute } from 'util/hook/useRouter';

import HealthReportRoute from './HealthReport';
import MembersRoute from './Members';
import BlogsRoute from './Blogs';
import ImageReportRoute from './ImageReport';
import UserInfoRoute from './UserInfo';
import StaffRoute from './Staff';
import SignUpRoute from './SignUp';
import ForgotPasswordRoute from './ForgotPassword';
import AppointmentsRoute from './Appointments';
import WelcomeRoute from './Welcome';
import EyeReportRoute from './EyeReport';
import CourseReservationRoute from './CourseReservation';
import { getMedicalProgressList, getAllDistrictList } from 'models/medicalProgress';
import { STORAGE_KEY, StorageType, getItemFromStorage } from 'util/hook/useStorage';
import DoctorRegistrationRoute from './DoctorRegistration';
import CheckInItemRoute from './CheckInItem';

let isFirstInit = true;

export interface RouteMenu {
	id: string;
	title: string;
	icon: string;
	to: string;
	subMenus: RouteMenu[];
}

const childrenHomeRoute: CustomRoute = {
	path: '',
	components: () => [import(/* webpackChunkName: 'Login' */ './Login')],
	render: ([Login]) => <Login />,
	onEnter: async ({ next }) => {
		console.log('on Enter Login');
		const children = await next();
		console.log('on Enter Login / end');
		return children;
	},
};

const childrenMedicalProgressRoute: CustomRoute = {
	path: '/medical-progress',
	components: () => [import(/* webpackChunkName: 'Login' */ './MedicalProgress')],
	render: ([MedicalProgress]) => <MedicalProgress />,
	onEnter: async ({ store, next }) => {
		console.log('on Enter MedicalProgress');
		await store.dispatch(getMedicalProgressList());
		setInterval(() => {
			store.dispatch(getMedicalProgressList());
		}, 10000);
		await store.dispatch(getAllDistrictList());
		const children = await next();
		console.log('on Enter MedicalProgress / end');
		return children;
	},
};

const childrenDiagnosisInfoRoute: CustomRoute = {
	path: '/diagnosis-info',
	components: () => [import(/* webpackChunkName: 'Login' */ './DiagnosisInfo')],
	render: ([DiagnosisInfo]) => <DiagnosisInfo />,
	onEnter: async ({ next }) => {
		console.log('on Enter DiagnosisInfo');
		const children = await next();
		console.log('on Enter DiagnosisInfo / end');
		return children;
	},
};

const routes: CustomRoute = {
	path: '/',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ next, store }) => {
		console.log('on Enter Root');

		const { routing, user: {device} } = store.getState();

		if (routing.search.includes('src=app')) {
			store.dispatch(setIsWebview(true));
		}

		if (isFirstInit) {
			isFirstInit = false;
			store.dispatch(loadTokenFromLocalstorage());
			await store.dispatch(getUserPermission());
		}

		/**
		 * 檢查有沒有填寫過初診單
		 * https://fox.25sprout.com/dianthus/dianthus_app/PM_template/-/issues/381
		 */
		const patientForm = getRouteQuery('patientForm');

		if (patientForm === 'success') {
			store.dispatch(
				openToast({
					toastId: nanoid(),
					status: ToastStatus.SUCCESS,
					text: '填寫完成，您的會員資料已同步初診單更新',
				}),
			);
			const selectedOutpatient:any = getItemFromStorage<number>(
				STORAGE_KEY.SELECTED_OUTPATIENT,
				StorageType.LOCAL,
			);

			if (selectedOutpatient?.id && device !== 'app') {
				history.push(`${ROUTE_PATHS.APPOINTMENTS}/${ROUTE_PATHS.REGISTRATIONS}/${ROUTE_PATHS.REGISTRATION_START}?step=3&first=1`);
			}
		}

		if (patientForm === 'failed') {
			store.dispatch(
				openToast({
					toastId: nanoid(),
					status: ToastStatus.WARNING,
					text: '初診單填寫未完成，請重新填寫',
				}),
			);
			history.push(`/${ROUTE_PATHS.WELCOME}`);
		}

		// 為了抓 token 最新的資料
		const {
			auth: { token },
		} = store.getState();

		// 已登入（有 token）卻進入不用 token 的頁面的話，就導回 "影像報告查詢"
		if (token && !isNeedTokenPage(window.location.pathname) && !isNotNeedTokenPage(window.location.pathname)) {
			history.push(`/${ROUTE_PATHS.IMAGE_REPORT}`);
		}

		if (!token && isNeedTokenPage(window.location.pathname)) {
			history.push('/');
		}

		const children = await next();
		console.log('on Enter Root / end');

		return children;
	},
	children: [
		childrenHomeRoute,
		MembersRoute,
		BlogsRoute,
		ImageReportRoute,
		HealthReportRoute,
		UserInfoRoute,
		StaffRoute,
		SignUpRoute,
		ForgotPasswordRoute,
		AppointmentsRoute,
		WelcomeRoute,
		EyeReportRoute,
		childrenMedicalProgressRoute,
		CourseReservationRoute,
		DoctorRegistrationRoute,
		CheckInItemRoute,
		childrenDiagnosisInfoRoute,
	],
};

export default routes;
