import React from 'react';

import { getBranchList } from 'models/departmentRegistration';
import { getRegistrationRecords } from 'models/recordsRegistration';
import { CustomRoute } from 'util/hook/useRouter';

import RegistrationRecord from './Record';


const routes: CustomRoute = {
	path: '/records',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ next }) => {
		const children = await next();
		return children;
	},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'registrationRecords' */ './component')],
			render: ([RegistrationRecords]) => <RegistrationRecords />,
			onEnter: async ({ next, store }) => {
				console.log('on Enter registrations records');
				await store.dispatch(getBranchList());
				await store.dispatch(getRegistrationRecords());
				console.log('on Enter registrations records / end');
				const children = await next();
				return children;
			},
		},
		RegistrationRecord,
	],
};

export default routes;
