import { createAction, handleActions, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { Dispatch } from 'redux';
import history from 'store/history';
import { nanoid } from 'nanoid';

import { api } from 'util/api';
import { NewbornHospitalizationListResource } from 'util/api/swaggerApi/data-contracts';
import { HospitalBranch } from 'util/options';
import { SelectorType, SelectorValueType } from './inpatientList';
import { updateAccessToken } from './auth';

import { GetState, State as GlobalState } from './reducers';

export interface InspectionReportType {
	name: string;
	date: string;
	result: string;
	memo: string;
}

export interface ExtendedNewbornHospitalizationListResource extends NewbornHospitalizationListResource {
	id: string;
	color?: string;
}

export interface ExtendedInspectionReportType extends InspectionReportType {
	id: string;
	resultValue: string;
}

export interface State {
	loading: boolean;
	allNewbornList: ExtendedNewbornHospitalizationListResource[];
	tyNewbornList: ExtendedNewbornHospitalizationListResource[];
	mqNewbornList: ExtendedNewbornHospitalizationListResource[];
	xsNewbornList: ExtendedNewbornHospitalizationListResource[];
	yzNewbornList: ExtendedNewbornHospitalizationListResource[];
	slNewbornList: ExtendedNewbornHospitalizationListResource[];
	txNewbornList: ExtendedNewbornHospitalizationListResource[];
	currentListDetail: ExtendedNewbornHospitalizationListResource;
	currentFilteredList: ExtendedNewbornHospitalizationListResource[];
	currentInspectionReport: ExtendedInspectionReportType[];
	selectorValue: SelectorType;
}

const defaultInspectionReportState: ExtendedInspectionReportType[] = [{
	id: '',
	resultValue: '',
	name: '',
	date: '',
	result: '',
	memo: '',
}];

export const defaultState: State = {
	loading: false,
	allNewbornList: [],
	tyNewbornList: [],
	mqNewbornList: [],
	xsNewbornList: [],
	yzNewbornList: [],
	slNewbornList: [],
	txNewbornList: [],
	currentListDetail: {} as ExtendedNewbornHospitalizationListResource,
	currentFilteredList: [],
	currentInspectionReport: defaultInspectionReportState,
	selectorValue: {department: 'all', doctor: 'all'},
};

export const getAllNewbornList = createAction(
	'GET_ALL_NEWBORN_LIST',
	() => async (dispatch: Dispatch) => {
		const { v1StaffNewbornHospitalizationListList } = api;
		try {
			const results = await Promise.allSettled([
				v1StaffNewbornHospitalizationListList({ hospital_branch: 'xs' }),
				v1StaffNewbornHospitalizationListList({ hospital_branch: 'mq' }),
				v1StaffNewbornHospitalizationListList({ hospital_branch: 'yz' }),
				v1StaffNewbornHospitalizationListList({ hospital_branch: 'sl' }),
				v1StaffNewbornHospitalizationListList({ hospital_branch: 'ty' }),
				v1StaffNewbornHospitalizationListList({ hospital_branch: 'tx' }),
			]);

			const allNewbornList: ExtendedNewbornHospitalizationListResource[] = [];
			const tyList: ExtendedNewbornHospitalizationListResource[] = [];
			const mqList: ExtendedNewbornHospitalizationListResource[] = [];
			const xsList: ExtendedNewbornHospitalizationListResource[] = [];
			const yzList: ExtendedNewbornHospitalizationListResource[] = [];
			const slList: ExtendedNewbornHospitalizationListResource[] = [];
			const txList: ExtendedNewbornHospitalizationListResource[] = [];


			results.forEach((result, index) => {
				if (result.status === 'fulfilled') {
					const { data } = result.value.data as unknown as { data: NewbornHospitalizationListResource[] };

					const dataWithId = data.map(item => ({
						...item,
						id: nanoid(),
					}));

					switch (index) {
						case 0:
							xsList.push(...dataWithId);
							break;
						case 1:
							mqList.push(...dataWithId);
							break;
						case 2:
							yzList.push(...dataWithId);
							break;
						case 3:
							slList.push(...dataWithId);
							break;
						case 4:
							tyList.push(...dataWithId);
							break;
						case 5:
							txList.push(...dataWithId);
							break;
						default:
							break;
					}
					allNewbornList.push(...dataWithId);
				} else if (result.status === 'rejected') {
					console.log('newborn list request failed:', result.reason);
					const { status } = result.reason as { status: number };
					if (status === 401) {
						dispatch(updateAccessToken(''));
						history.push('/');
					}
				}
			});
			dispatch({ type: 'SET_FILTERED_NEWBORN_LIST', payload: allNewbornList });
			dispatch({ type: 'SET_TY_NEWBORN_LIST', payload: tyList });
			dispatch({ type: 'SET_MQ_NEWBORN_LIST', payload: mqList });
			dispatch({ type: 'SET_XS_NEWBORN_LIST', payload: xsList });
			dispatch({ type: 'SET_YZ_NEWBORN_LIST', payload: yzList });
			dispatch({ type: 'SET_SL_NEWBORN_LIST', payload: slList });
			dispatch({ type: 'SET_TX_NEWBORN_LIST', payload: txList });

			return allNewbornList;
		} catch (e) {
			console.log('newborn list error', e);
			const { status } = e as { status: number };
			if (status === 401) {
				dispatch(updateAccessToken(''));
				history.push('/');
			}
			return [];
		}
	},
);

export const setCurrentListDetail = createAction(
	'SET_CURRENT_LIST_DETAIL',
	(detail: ExtendedNewbornHospitalizationListResource) => detail,
);

export const filterNewbornList = createAction(
	'SET_FILTERED_NEWBORN_LIST',
	() => (_: Dispatch, getState: GetState) => {
		let isFirstLoad = true;  // 用於標記是否為第一次載入
		const {
			newbornList
		} = getState();
		const department = newbornList.selectorValue.department;
		const midwife = newbornList.selectorValue.doctor;

		if (department === '' && midwife === '') {
			return [];
		}

		if (midwife === 'all' && department === 'all') {
			return newbornList.allNewbornList;
		}

		let departmentListKey = department ? `${department}NewbornList` : 'allNewbornList';
		departmentListKey = departmentListKey as keyof typeof newbornList;
		const departmentList = newbornList[departmentListKey as keyof typeof newbornList] as ExtendedNewbornHospitalizationListResource[] || newbornList.allNewbornList;

		let filteredList = departmentList;

		if (midwife && midwife !== 'all') {
			filteredList = departmentList.filter(item => item.midwife === midwife);
			// if (filteredList.length === 0) {
			// 	filteredList = departmentList;
			// }
		} else {
			// filteredList = departmentList.filter(item => item.midwife === midwife);
		}

		return filteredList;
	 },
);

export const getNewbornInspectionReport = createAction(
	'GET_NEWBORN_INSPECTION_REPORT',
	(department: HospitalBranch , babyPatientNo: string ) => async (dispatch: Dispatch) => {
		const { v1StaffNewbornInspectionReportList } = api;
		try {
			const { data } = await v1StaffNewbornInspectionReportList({ hospital_branch: department, patient_no: babyPatientNo});
			if (!data?.data || data?.data.length === 0) {
				return [];
			}
			const dataWithId = data?.data
				?.filter(item => item.result !== 'NULL')
				.map(item => {
					let resultValue = item.result;
					if (item.result?.includes('不通過')) {
						resultValue = '需追蹤';
					} else if (item.result === '通過') {
						resultValue = '無異常';
					} else if (item.result === '雙耳通過') {
						resultValue = '雙耳通過';
					}

					return {
						...item,
						id: nanoid(),
						resultValue,
					}
				});
			return dataWithId as ExtendedInspectionReportType[];
		} catch (e) {
			console.log('newborn inspection report error', e);
			const { status } = e as { status: number };
			if (status === 401) {
				dispatch(updateAccessToken(''));
				history.push('/');
			}
			return [];
		}
	})

export const setNewbornSelectorValue = createAction(
	'SET_NEWBORN_SELECTOR_VALUE',
	(type: 'department' | 'doctor', value: string) => ({type, value}),
);

export const cleanNewbornSelectorValue = createAction('CLEAN_NEWBORN_SELECTOR_VALUE');

export const cleanNewbornInspectionReport = createAction('CLEAN_NEWBORN_INSPECTION_REPORT');

export const initNewBornDoctorValue = createAction(
	'INIT_VALUE', () => async (dispatch: Dispatch, getState: GetState) => {
		const { user: { staffPermissionInfo } } = getState();

		if (staffPermissionInfo.isDoctor) {
			dispatch(setNewbornSelectorValue('doctor', staffPermissionInfo.defaultSelectorDoctorValue));
		}
		dispatch(filterNewbornList());
	}
);

export const reducer = {
	// Workaround: HandleActions 目前定義無法支援多種 action 形式
	newbornList: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_ALL_NEWBORN_LIST_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_ALL_NEWBORN_LIST_FULFILLED: (
				state,
				action: Action<ExtendedNewbornHospitalizationListResource[]>,
			) => ({
				...state,
				allNewbornList: [...action.payload],
				loading: false,
			}),
			SET_TY_NEWBORN_LIST: (state, action: Action<ExtendedNewbornHospitalizationListResource[]>) => ({
				...state,
				tyNewbornList: [...action.payload],
				loading: false,
			}),
			SET_MQ_NEWBORN_LIST: (state, action: Action<ExtendedNewbornHospitalizationListResource[]>) => ({
				...state,
				mqNewbornList: [...action.payload],
				loading: false,
			}),
			SET_XS_NEWBORN_LIST: (state, action: Action<ExtendedNewbornHospitalizationListResource[]>) => ({
				...state,
				xsNewbornList: [...action.payload],
				loading: false,
			}),
			SET_YZ_NEWBORN_LIST: (state, action: Action<ExtendedNewbornHospitalizationListResource[]>) => ({
				...state,
				yzNewbornList: [...action.payload],
				loading: false,
			}),
			SET_SL_NEWBORN_LIST: (state, action: Action<ExtendedNewbornHospitalizationListResource[]>) => ({
				...state,
				slNewbornList: [...action.payload],
				loading: false,
			}),
			SET_TX_NEWBORN_LIST: (state, action: Action<ExtendedNewbornHospitalizationListResource[]>) => ({
				...state,
				txNewbornList: [...action.payload],
				loading: false,
			}),
			SET_CURRENT_LIST_DETAIL: (state, action: Action<ExtendedNewbornHospitalizationListResource>) => ({
				...state,
				currentListDetail: action.payload,
				loading: false,
			}),
			SET_FILTERED_NEWBORN_LIST: (state, action: Action<ExtendedNewbornHospitalizationListResource[]>) => ({
				...state,
				currentFilteredList: action.payload,
				loading: false,
			}),
			GET_NEWBORN_INSPECTION_REPORT_FULFILLED: (state, action: Action<ExtendedInspectionReportType[]>) => ({
				...state,
				currentInspectionReport: action.payload,
				loading: false,
			}),
			SET_NEWBORN_SELECTOR_VALUE: (state, action: Action<SelectorValueType>) => ({
				...state,
				selectorValue: {
					...state.selectorValue,
					[action.payload.type]: action.payload.value,
				},
			}),
			CLEAN_NEWBORN_SELECTOR_VALUE: state => ({
				...state,
				selectorValue: {
					department: 'all', doctor: 'all'
				},
			}),
			CLEAN_NEWBORN_INSPECTION_REPORT: state => ({
				...state,
				currentInspectionReport: [...defaultInspectionReportState],
			}),
		},
		defaultState,
	),
};

const newbornListActionsMap = {
	getAllNewbornList,
	setCurrentListDetail,
	filterNewbornList,
	getNewbornInspectionReport,
	setNewbornSelectorValue,
	cleanNewbornSelectorValue,
	cleanNewbornInspectionReport,
};

const mapHooksToState = (state: GlobalState) => ({
	allNewbornList: state.newbornList.allNewbornList,
	ty: state.newbornList.tyNewbornList,
	mq: state.newbornList.mqNewbornList,
	xs: state.newbornList.xsNewbornList,
	yz: state.newbornList.yzNewbornList,
	sl: state.newbornList.slNewbornList,
	tx: state.newbornList.txNewbornList,
	currentListDetail: state.newbornList.currentListDetail,
	currentFilteredList: state.newbornList.currentFilteredList,
	currentInspectionReport: state.newbornList.currentInspectionReport,
	selectorValue: state.newbornList.selectorValue,
});

type NewbornListSelector = ReturnType<typeof mapHooksToState>;
type NewbornListActionsMap = typeof newbornListActionsMap;

export const useNewbornList = () =>
	useRedux<NewbornListSelector, NewbornListActionsMap>(
		mapHooksToState,
		newbornListActionsMap,
	);
